import React, { useEffect, useRef, useState } from 'react';
import ContainedButton from './buttons/ContainedButton';
import OutlinedButton from './buttons/OutlinedButton';

const CookieConsent = () => {
  const CookieBannerRef = useRef(null);
  const [show, setShow] = useState(false);
  const cookieStorage = {
    getItem: (item) => {
      const cookies = document.cookie
        .split(';')
        .map(cookie => cookie.split('='))
        .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
      return cookies[item];
    },
    setItem: (item, value) => {
      document.cookie = `${item}=${value};`
    }
  }

  const storageType = cookieStorage;
  const consentPropertyName = 'jdc_consent';
  const shouldShowPopup = () => !storageType.getItem(consentPropertyName);
  const saveToStorage = () => storageType.setItem(consentPropertyName, true);

  const acceptCookies = () => {
    saveToStorage(storageType);
    setShow(false);
  }

  useEffect(() => {
    if (shouldShowPopup(storageType)) {
      setTimeout(() => {
        setShow(true);
      }, 2000);
    }
  }, [])

  return (
    <div ref={CookieBannerRef} className={`${show ? 'fixed bottom-0 z-30' : 'hidden'} w-full py-8 bg-white border border-t-[#cecece] rounded-t-lg sm:rounded-none`}>
      <div className="container mx-auto flex flex-col gap-4 sm:gap-0 sm:flex-row justify-between">
        <p className='text-sm sm:text-base' >By using this site you agree to our <a href="#" className="text-accent">Terms and Conditions</a>.
          Please accept these before using the site.
        </p>
        <div className="flex gap-4 justify-center">
          <ContainedButton onClick={acceptCookies}>
            Accept
          </ContainedButton>
          <OutlinedButton onClick={() => setShow(false)}>
            Decline
          </OutlinedButton>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;

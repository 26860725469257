import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import SearchList from "../../components/search/SearchList";
import moment from "moment-timezone";

const Search = () => {
  const [query, setQuery] = useState("");
  const [search, setSearch] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/search?q=${query}`);
      setSearch(res.data);
    };
    if (query.length > 3) fetchData();
  }, [query]);

  useEffect(() => {
    if (query.length === 0) {
      setSearch([])
    }
  }, [query])

  return (
    <div className="w-full min-h-[50vh]" data-search-bar>
      <div className='container px-1 h-fit max-w-[820px] bg-white mx-auto'>
        <div className="flex flex-col w-full mt-6 px-6">
          <p className="text-[#4a4a4a] font-extrabold mb-4 text-sm">Type your search and hit enter</p>
          <div className='pb-6 border border-t-0 border-r-0 border-l-0 border-b-neutral-400'>
            <input
              onChange={(e) => setQuery(e.target.value.toLowerCase())}
              type="search"
              name="search"
              placeholder="Search"
              className="border-0 outline-none text-2xl font-extrabold"
            />
          </div>
        </div>
      </div>
      <div className="mt-8">
        <SearchList search={search} />
      </div>
    </div>
  );
};

export default Search;

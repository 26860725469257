import React from 'react'

// import Link
import { Link } from 'react-scroll'

const FooterLinks = (props) => {
  const { data } = props
  return (
    <>
      {data.map((item, index) => {
        return (
          <nav key={index} className='w-1/3'>
            <ul className='flex flex-col text-[15px]'>
              <h3 className='font-semibold capitalize mb-6 text-neutral-400'>{item.name}</h3>
              {item.menu.map((item, index) => {
                return (
                  <li className='text-neutral-400 hover:text-accent-hover cursor-pointer md:text-sm mb-4' key={index}>
                    <Link
                      to={item.href}
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-70}
                      className='transition-all duration-300 capitalize'
                    >
                      {item.name}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </nav>
        )
      })}
    </>
  )
}

export default FooterLinks

import React, { useState, useContext } from 'react'
import { AuthContext } from "../../context/authContext";
import { BsSearch } from 'react-icons/bs';
// import icons
/* import { XIcon } from '@heroicons/react/outline'
import { MenuAlt3Icon } from '@heroicons/react/outline' */
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

// import navigation data
import { categories } from '../../data'

// import components
import Socials from '../ui/Socials'

// import framer
import { motion } from 'framer-motion'

// import Link
import { Link } from "react-router-dom";

const HeaderMenuMobileMotion = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { currentUser, logout, setNext } = useContext(AuthContext);
  const circleVariants = {
    hidden: {
      scale: 0,
    },
    visible: {
      scale: 180,
      transition: {
        type: 'spring',
        stiffness: 160,
        damping: 60,
      },
    },
  }

  const ulVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.1,
      },
    },
  }

  const reset = () => {
    setIsOpen(false);
    setNext(4)
  }

  return (
    <nav>
      <div
        onClick={() => setIsOpen(true)}
        className='cursor-pointer'
      >
        <MenuIcon sx={{ fontSize: '28px' }} />
      </div>

      {/* circle */}
      <motion.div
        variants={circleVariants}
        initial='hidden'
        animate={isOpen ? 'visible' : 'hidden'}
        className='w-4 h-4 rounded-full bg-[#e74b60] fixed top-0 left-0'
      ></motion.div>

      <motion.ul
        variants={ulVariants}
        initial='hidden'
        animate={isOpen ? 'visible' : ''}
        className={`${isOpen ? 'right-0' : '-left-full'
          } fixed top-0 bottom-0 w-full flex flex-col justify-center items-center transition-all duration-300 overflow-hidden`}
      >
        <div
          onClick={() => setIsOpen(false)}
          className='cursor-pointer absolute top-8 left-8 uppercase text-white hover:text-neutral-600 font-extrabold'
        >
          <CloseIcon className='w-8 h-8' />
        </div>
        {categories.map((item, index) => {
          return (
            <li key={index} className='mb-6 sm:mb-8'>
              <Link
                to={`/${item.href}`}
                onClick={reset}
                className='text-base sm:text-xl cursor-pointer uppercase text-white hover:text-neutral-600 font-extrabold'
              >
                {item.name}
              </Link>
            </li>
          )
        })}
        <Link
          to="/search"
          onClick={() => setIsOpen(false)}
          className='flex gap-2 items-center text-base sm:text-xl mb-6 sm:mb-8 cursor-pointer uppercase text-white hover:text-neutral-600 font-extrabold'
        >
          <BsSearch /> Search
        </Link>
        {process.env.REACT_APP_NODE_ENV === 'development' ? (
          currentUser ? (
            <>
              <span>{currentUser?.username} is logged in</span>
              <Link
                to="/"
                onClick={logout}
                className='text-base sm:text-xl cursor-pointer uppercase text-white hover:text-neutral-600 font-extrabold'
              >
                Logout
              </Link>
            </>
          ) : (
            <Link
              to="/login"
              className='text-base sm:text-xl cursor-pointer uppercase text-white hover:text-neutral-600 font-extrabold'
            >
              Login
            </Link>
          )
        ) : null
        }
        {/* <Socials /> */}
      </motion.ul>
    </nav>
  )
}

export default HeaderMenuMobileMotion

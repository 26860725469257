import React, { useState } from 'react';
import HomePostCard from './HomePostCard';
import SkyscraperBanner from '../../components/banner/SkyscraperBanner';

const HomePostList = (props) => {
    const { posts, cat } = props;

    /* const chunkSize = 5;
    let chunk;
    for (let i = 0; i < posts.length; i += chunkSize) {
        chunk = posts.slice(i, i + chunkSize);
        console.log(chunk);
    } */

    return (
        <section className='flex items-start pt-0 sm:pt-4 sm:pb-[100px] bg-white min-h-[60vh]'>
            <div className='container flex flex-col items-start gap-1 mx-auto '>
                <h2 className={`${!cat ? 'hidden' : 'block'} text-md text-neutral-800 sm:text-2xl font-semibold mb-0 sm:mb-2 capitalize`}>{cat ? `The latest stories about ${posts[0]?.cat}` : 'LATEST STORIES'}</h2>
                <div className='w-full flex justify-between h-fit relative'>
                    <div className='h-full w-full md:w-2/3 mb-4'>
                        <HomePostCard posts={posts} cat={cat} />
                    </div>
                    <SkyscraperBanner isPost={posts} />
                </div>
            </div>
        </section>
    );
};

export default HomePostList;

import React, { useRef, useState } from "react";
import ReactQuill from "react-quill";
import OutlinedButton from "../../components/ui/buttons/OutlinedButton";
import ModalDelete from "../../components/ui/ModalDelete";
import "react-quill/dist/quill.snow.css";
import uploadImg from '../../img/upload.jpg';
const imageMimeType = /image\/(png|jpg|jpeg)/i;

const WriteParagraph = (props) => {
  const {
    paragraphFields,
    setParagraphFields,
  } = props;

  const [open, setOpen] = useState(false);
  const [modalFields, setModalFields] = useState({})
  const handleOpen = (index, title, img) => {
    /* console.log('index :::', index);
    console.log('title :::', title);
    console.log('img :::', img); */
    setModalFields({ index, title, img })
    setOpen(true);
  }
  const handleClose = () => setOpen(false);

  const paragraphTextRef = useRef([]);
  const paragraphImageRef = useRef([]);
  const [paragraphFiles, setParagraphFiles] = useState([]);

  const paragraphImgHandler = async (event, index, paragraphId) => {
    let data = [...paragraphFields];
    data[index]['paragraph_img'] = event.target.files[0]
    setParagraphFields(data)

    let paragraphImg = [...paragraphFiles];
    paragraphImg[index] = paragraphImageRef.current[index].files[0];
    if (!paragraphImg[index].type.match(imageMimeType)) {
      alert("Image mime type is not valid");
      return;
    }

    let fileReader = new FileReader();
    fileReader.readAsDataURL(paragraphImg[index]);
    fileReader.onload = (e) => {
      const { result } = e.target;
      paragraphFiles.push(result)
      for (let i = 0; i < paragraphFields.length; i++) {
        if (paragraphFields[i]['id'] === paragraphId) {
          let imgId = `paragraph-img${index}${paragraphId}`
          document.getElementById(imgId).src = result
        }
      }
    }
  }

  const handleFormChange = (event, index) => {
    let data = [...paragraphFields];
    data[index][event.target.name] = event.target.value;
    setParagraphFields(data);
  }

  const handleTextChange = (event, index) => {
    let data = [...paragraphFields];
    if (paragraphTextRef.current[index].value) {
      data[index].paragraph_text = paragraphTextRef.current[index].value;
    }
    setParagraphFields(data);
  }

  const addFields = () => {
    let object = {
      paragraph_title: '',
      paragraph_text: '',
      paragraph_img: '',
      paragraph_img_credit: '',
      post_id: ''
    }
    setParagraphFields([...paragraphFields, object])
  }

  const removeFields = (index) => {
    let data = [...paragraphFields]
    data.splice(index, 1)
    setParagraphFields(data)
    setOpen(false)
  }

  return (
    <div className="container mx-auto">
      <h2 className="text-neutral-800 text-2xl mb-4 w-full">Add Paragraph</h2>
      <form>
        {paragraphFields.map((item, index) => (
          <div key={index} className="w-full mb-12">
            <h2 className="text-neutral-800 text-xl mb-4 w-full">{`Paragraph ${index + 1}`}</h2>
            <div className="w-full flex gap-4 justify-between items-stretch">
              <div className="flex flex-col gap-4 w-3/4">
                <input
                  className="w-full p-1.5 border border-neutral-300"
                  name="paragraph_title"
                  type="text"
                  placeholder="Paragraph Title"
                  onChange={(event) => handleFormChange(event, index)}
                  defaultValue={item.paragraph_title || ""}
                />
                <div className="h-[400px] overflow-y-scroll border-b-[0.5px] border-t-[0.5px] border-neutral-300">
                  <ReactQuill
                    ref={item => (paragraphTextRef.current[index] = item)}
                    className="h-full border-none"
                    theme="snow"
                    value={item?.paragraph_text}
                    defaultValue={item?.paragraph_text}
                    onChange={(event) => handleTextChange(event, index)}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-between gap-4 w-1/4">
                <div className="flex flex-col flex-1 gap-2 border border-neutral-300 p-4">
                  <h2 className="text-neutral-800 text-2xl mb-4">{`Paragraph ${index + 1} image`}</h2>
                  <div className="flex flex-col gap-4 mb-4">
                    <label className="text-sm" htmlFor='file'>Browse paragraph image</label>
                    <input
                      ref={item => (paragraphImageRef.current[index] = item)}
                      type="file"
                      id='file'
                      accept='.png, .jpg, .jpeg'
                      onChange={(event) => paragraphImgHandler(event, index, item.id)}
                    />
                  </div>
                  <div className="flex justify-between w-full border border-neutral-300 mb-4">
                    {
                      item.paragraph_img ?
                        <img className="w-full " id={"paragraph-img" + index + item.id} src={`https://res.cloudinary.com/dfjtdwtka/image/upload/v${item?.paragraph_img}`} alt="preview" />
                        :
                        <img className="w-full" id={"paragraph-img" + index + item.id} src={uploadImg} alt="" />
                    }
                  </div>
                  <input
                    className="w-full p-1.5 border border-neutral-300 mb-4"
                    name="paragraph_img_credit"
                    type="text"
                    placeholder="Image Credit"
                    onChange={(event) => handleFormChange(event, index)}
                    defaultValue={item.paragraph_img_credit || ""}
                  />
                  <OutlinedButton /* className="border border-neutral-400 px-2 py-1" */ onClick={() => handleOpen(index, item?.paragraph_name, item?.paragraph_img)}>Delete</OutlinedButton>
                </div>
              </div>
            </div>
          </div>
        ))}
      </form>
      <div className="w-full flex justify-start gap-4">
        <OutlinedButton /* className="border border-neutral-400 px-2 py-1" */ onClick={addFields}>+ Add Paragraph</OutlinedButton>
      </div>
      <ModalDelete open={open} handleDelete={() => removeFields(modalFields.index)} handleClose={handleClose} title={modalFields.title} >
        paragraph
      </ModalDelete>
    </div>
  );
};

export default WriteParagraph;

import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { categories } from "../../data";
import uploadImg from '../../img/upload.jpg';
import SmallOutlinedButton from '../../components/ui/buttons/SmallOutlinedButton';
import SmallContainedButton from '../../components/ui/buttons/SmallContainedButton';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import WriteAuthor from "./WriteAuthor";

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#FF645C',
      contrastText: "#fff",
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    },
  },
});

const WriteHeader = (props) => {
  const [toggle, setToggle] = useState(false)

  const {
    handleSubmit,
    headerImgHandler,
    headerState,
    cat,
    setCat,
    headerTitle,
    setHeaderTitle,
    headerDescription,
    setHeaderDescription,
    headerImage,
    setHeaderImage,
    headerImgCredit,
    setHeaderImgCredit,
    headerText,
    setHeaderText,
    headerSubtitle,
    setHeaderSubtitle,
    homeHero01,
    setHomeHero01,
    homeHero02,
    setHomeHero02,
    homeHero03,
    setHomeHero03,
    headerImageDataURL,
    headerAuthor,
    setHeaderAuthor
  } = props;

  useEffect(() => {
    setToggle(prev => !prev)
  }, [headerImage]);

  const toggleHero01 = () => {
    setHomeHero01(1)
    setHomeHero02(0)
    setHomeHero03(0)
  }

  const toggleHero02 = () => {
    setHomeHero01(0)
    setHomeHero02(1)
    setHomeHero03(0)
  }

  const toggleHero03 = () => {
    setHomeHero01(0)
    setHomeHero02(0)
    setHomeHero03(1)
  }

  return (
    <div className="container mx-auto">
      <h2 className="text-neutral-800 text-2xl mb-4 w-full">Post Header</h2>
      <div className="w-full flex gap-4 justify-between">
        <div className="flex flex-col justify-between w-3/4">
          <div className="w-full flex items-center gap-4 px-2 py-1 border border-neutral-300">
            <div>
              <h2 className="text-neutral-800 text-lg mb-2">Category</h2>
            </div>
            <div className="header-categories grid grid-flow-col auto-cols-[21%] capitalize overflow-x-scroll">
              {categories.map((item, i) => (
                <div key={i} className="flex items-center justify-center gap-2 mb-1">
                  <input
                    type="radio"
                    checked={cat === item.name}
                    name="cat"
                    defaultValue={item.name}
                    id={item.category}
                    onChange={(e) => setCat(e.target.value)}
                  />
                  <label className="text-sm" htmlFor={item.name}>{item.name}</label>
                </div>
              ))}
            </div>
          </div>
          <WriteAuthor headerAuthor={headerAuthor} setHeaderAuthor={setHeaderAuthor} />
          <input
            className="w-full p-1.5 border border-neutral-300"
            type="text"
            placeholder="Title"
            onChange={(e) => setHeaderTitle(e.target.value)}
            defaultValue={headerTitle}
          />
          <input
            className="w-full p-1.5 border border-neutral-300"
            type="text"
            placeholder="Description"
            onChange={(e) => setHeaderDescription(e.target.value)}
            defaultValue={headerDescription}
          />
          <div className="h-[200px] overflow-y-scroll border-b-[0.5px] border-t-[0.5px] border-neutral-300">
            <ReactQuill
              className="h-full border-none"
              theme="snow"
              defaultValue={headerText}
              onChange={setHeaderText}
            />
          </div>
          <input
            className="w-full p-1.5 border border-neutral-300"
            type="text"
            placeholder="Subtitle"
            onChange={(e) => setHeaderSubtitle(e.target.value)}
            defaultValue={headerSubtitle}
          />
        </div>
        <div className="flex w-1/4">
          <div className="w-full flex flex-col justify-between gap-2 border border-neutral-300 p-4">
            <h2 className="text-neutral-800 text-xl ">{headerState ? 'Update Post' : 'Publish'}</h2>
            {/*<span className="text-xs">
              <b>Status: </b> Draft
            </span>
            <span className="text-xs">
              <b>Visibility: </b> Public
            </span> */}
            <div className="flex flex-col gap-2">
              <label className="text-sm" htmlFor='image'>Browse Cover Image</label>
              <input
                type="file"
                id='image'
                accept='.png, .jpg, .jpeg'
                onChange={headerImgHandler}
              />
            </div>
            <div className="flex justify-between w-full border border-neutral-300">
              {
                headerImageDataURL ?
                  <img className="w-full " src={headerImageDataURL} alt="preview" />
                  :
                  headerImage ?
                    <img className="w-full " src={`https://res.cloudinary.com/dfjtdwtka/image/upload/v${headerImage}`} alt='' />
                    :
                    <img className="w-full" src={uploadImg} alt="" />
              }
            </div>
            <input
              className="w-full p-1.5 border border-neutral-300"
              type="text"
              placeholder="Image Credit"
              onChange={(e) => setHeaderImgCredit(e.target.value)}
              defaultValue={headerImgCredit}
            />
            <div className=" w-full flex flex-col gap-2 my-4">
              <h2 className="text-neutral-800">Set Home Hero</h2>
              <div className="flex gap-1">
                <div onClick={toggleHero01} className={`${homeHero01 === 1 ? 'bg-[#FF596F] text-white' : null} w-1/2 h-20 flex items-center justify-center border border-[#cecece] rounded-sm hover:bg-[#FF596F] hover:text-white`}>1</div>
                <div className="w-1/2 h-20 flex flex-col gap-1">
                  <div onClick={toggleHero02} className={`${homeHero02 === 1 ? 'bg-[#FF596F] text-white' : null} w-full h-10 flex items-center justify-center border border-[#cecece] rounded-sm hover:bg-[#FF596F] hover:text-white`}>2</div>
                  <div onClick={toggleHero03} className={`${homeHero03 === 1 ? 'bg-[#FF596F] text-white' : null} w-full h-10 flex items-center justify-center border border-[#cecece] rounded-sm hover:bg-[#FF596F] hover:text-white`}>3</div>
                </div>
                {/* <label className="text-sm">Set as Hero 01</label>
                <input
                  type="radio"
                  checked={homeHero01 === 1 ? true : false}
                  name="is-hero"
                  onChange={toggleHero01}
                />
              </div>
              <div className="flex justify-between">
                <label className="text-sm">Set as Hero 02</label>
                <input
                  type="radio"
                  checked={homeHero02 === 1 ? true : false}
                  name="is-hero"
                  onChange={toggleHero02}
                />
              </div>
              <div className="flex justify-between">
                <label className="text-sm">Set as Hero 03</label>
                <input
                  type="radio"
                  checked={homeHero03 === 1 ? true : false}
                  name="is-hero"
                  onChange={toggleHero03}
                /> */}
              </div>
            </div>
            <ThemeProvider theme={theme}>
              <div className="flex flex-wrap justify-between w-full text-sm">
                <SmallOutlinedButton /* className="cursor-pointer border border-neutral-400 py-[3px] px-[5px]" */>Save as a draft</SmallOutlinedButton>
                <SmallContainedButton /* className="cursor-pointer text-white bg-accent py-[3px] px-[6px]" */ onClick={handleSubmit}>{headerState ? 'Update Post' : 'Publish'}</SmallContainedButton>
              </div>
            </ThemeProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WriteHeader;

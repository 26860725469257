import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Avatar from '@mui/material/Avatar';
import OutlinedButton from "../../components/ui/buttons/OutlinedButton"
import ContainedButton from "../../components/ui/buttons/ContainedButton"
import SmallOutlinedButton from "../../components/ui/buttons/SmallOutlinedButton"
import SettingsAuthorEdit from "./SettingsAuthorEdit";
import ModalDelete from "../../components/ui/ModalDelete";
import _ from "lodash";

const SettingsAuthor = () => {
  let authorCloudinaryObj;

  //author states
  const [author, setAuthor] = useState([
    {
      author_name: '',
      author_role: '',
      author_img: '',
      author_bio: '',
    }]);
  const [authorBackup, setAuthorBackup] = useState([
    {
      author_name: '',
      author_role: '',
      author_img: '',
      author_bio: '',
    }]);
  const [isModal, setIsModal] = useState(false);
  const [authorImgId, setAuthorImgId] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/authors`,
          { withCredentials: true });
        let cloneRes01 = _.cloneDeep(res.data)
        let cloneRes02 = _.cloneDeep(res.data)
        if (res) {
          setAuthor(cloneRes01);
          setAuthorBackup(cloneRes02);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const [open, setOpen] = useState(false);
  const [modalFields, setModalFields] = useState({})
  const handleOpen = (index, title, img) => {
    /* console.log('index :::', index);
    console.log('title :::', title);
    console.log('img :::', img); */
    setModalFields({ index, title, img })
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false)
  }

  const toggleModal = (event) => {
    event.preventDefault()
    if (isModal) {
      setAuthor(authorBackup)
    }
    setIsModal(prev => !prev)
  }

  const addFields = () => {
    let object = {
      author_name: '',
      author_role: '',
      author_img: '',
      author_bio: '',
    }
    setAuthor([...author, object])
  }

  const removeFields = (index) => {
    let data = [...author]
    data.splice(index, 1)
    setAuthor(data)
    setOpen(false)
  }

  const uploadAuthorImage = async () => {
    try {
      if (author.length !== 0) {
        let authorId = [];
        let promises = [];
        /* console.log('authorCloudinaryObj arrives :::', authorCloudinaryObj); */
        for (let i = 0; i < authorCloudinaryObj.length; i++) {
          authorId.push(authorCloudinaryObj[i]['id']);
        }
        /* console.log('authorId after push:::', authorId); */
        for (let i = 0; i < authorCloudinaryObj.length; i++) {
          let file = authorCloudinaryObj[i].author_img;
          console.log('file :::', file);
          const formData = new FormData();
          formData.append("file", file);
          formData.append("upload_preset", 'e0qglk4j');
          promises.push(axios.post(`https://api.cloudinary.com/v1_1/dfjtdwtka/image/upload`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }).then((res) => {
            return res;
          }))
        }
        /* console.log('promises after id push :::', promises); */
        let data = await Promise.all(promises);
        for (let i = 0; i < data.length; i++) {
          data[i]['id'] = authorId[i]
        }
        /* console.log('return data :::', data); */
        return data;
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
    }
  };

  //submit form
  const handleSubmit = async (e) => {
    e.preventDefault();
    let updatedAuthorImgUrl;
    authorCloudinaryObj = author.filter(object => {
      return typeof object.author_img === 'object'
    })
    /* console.log('authorCloudinaryObj :::', authorCloudinaryObj); */
    let authorImgUrl = await uploadAuthorImage();
    /* console.log('authorImgUrl :::', authorImgUrl); */
    if (authorImgUrl !== undefined) {
      updatedAuthorImgUrl = author.filter((object, index) => {
        return typeof object.author_img === 'object'
      })
      /* console.log('updatedImgUrl :::', updatedImgUrl); */
      for (let i = 0; i < updatedAuthorImgUrl.length; i++) {
        updatedAuthorImgUrl[i]['author_img'] = authorImgUrl[i].data.version + '/' + authorImgUrl[i].data.public_id
      }
      /* author.map(object => updatedImgUrl.find(o => o.id === object.id) || object); */
      /* console.log('author after filter :::', author); */
    }
    try {
      /* console.log('author axios :::', author); */
      await axios(`${process.env.REACT_APP_API_URL}/authors/`, {
        data: {
          author
        },
        method: 'POST',
        withCredentials: true
      }).then()
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
    }
    setOpen(false)
  };

  return (
    <div className="w-5/6 mx-auto mb-12">
      <div className="w-full flex items-center justify-between py-2">
        <h2 className="w-1/3 text-xl text-neutral-800">Authors' List</h2>
        <div className="flex gap-4">
          {isModal ? (
            <>
              <OutlinedButton onClick={addFields}>+ Add Author</OutlinedButton>
              <ContainedButton onClick={handleSubmit} >Update Authors</ContainedButton>
            </>
          )
            : null}
          <OutlinedButton onClick={(e) => toggleModal(e)}>{isModal ? 'Cancel' : 'Edit Authors'}</OutlinedButton>
        </div>
      </div>

      <div className="w-full flex flex-col gap-2">
        {!isModal ?
          author?.map((item, index) => {
            return (
              <div key={item.id || index} className="flex">
                <div className="flex flex-col items-center justify-center w-1/3 px-8 py-4 border border-[#cecece] border-r-0">
                  <div className="flex justify-center mb-2">
                    <Avatar sx={{ width: 52, height: 52 }} id={"author-img" + index + item.id} src={`https://res.cloudinary.com/dfjtdwtka/image/upload/v${item?.author_img}`} alt={item?.author_name} />
                  </div>
                  <p className="text-sm font-bold text-neutral-800">{item.author_name}</p>
                  <p className="text-sm italic text-neutral-800">{item.author_role}</p>
                </div>
                <div className="flex flex-col gap-2 w-2/3 px-8 py-4 border border-[#cecece]">
                  <h2 className="font-bold text-neutral-800">Bio</h2>
                  <p className="text-sm text-neutral-800">{item.author_bio}</p>
                </div>
              </div>
            )
          }) : (
            <SettingsAuthorEdit
              author={author}
              setAuthor={setAuthor}
              authorImgId={authorImgId}
              setAuthorImgId={setAuthorImgId}
              handleOpen={handleOpen}
            />
          )}
      </div>

      <div className="w-full flex justify-start gap-4">
      </div>
      <ModalDelete open={open} handleDelete={() => removeFields(modalFields.index)} handleClose={handleClose} title={modalFields.title} >
        author
      </ModalDelete>
    </div>
  );
};

export default SettingsAuthor;

import React from 'react';
import { HashLink } from 'react-router-hash-link';

const SearchList = (props) => {
    const { search, setActive, queryNotFound } = props;
    let searchHeader = search[0];
    let searchProducts = search[1];

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -100;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    return (
        <section className='w-full h-full pb-12 bg-white'>
            <div className='container px-1 max-h-[400px] max-w-[820px] flex flex-col items-start mx-auto overflow-y-auto'>
                <div className='w-full'>
                    <div className='w-full'>
                        <div className='w-full flex flex-col gap-2 '>
                            {searchProducts?.map(item => {
                                return (
                                    <HashLink
                                        key={item.id}
                                        className='w-full text-neutral-600 font-medium hover:bg-neutral-200 hover:cursor-pointer px-6 py-2'
                                        onClick={() => setActive(prev => !prev)}
                                        to={`/post/${item?.post_id}#product${item?.id}`}
                                        scroll={(el) => scrollWithOffset(el)}
                                    >
                                        {item.product_name}
                                    </HashLink>
                                )
                            })}
                            {searchHeader?.map(item => {
                                return (
                                    <HashLink
                                        key={item.id}
                                        className='w-full text-neutral-600 font-medium hover:bg-neutral-200 hover:cursor-pointer px-6 py-2'
                                        onClick={() => setActive(prev => !prev)}
                                        to={`/post/${item.id}#top`}
                                    >
                                        {item.header_title}
                                    </HashLink>
                                )
                            })}
                            {
                                queryNotFound &&
                                <p className='text-neutral-600 text-center'>Unfortunately we could not find any item related to your search</p>
                            }
                        </div>
                    </div>
                    {/* <SearchPostCard posts={posts} /> */}
                </div>
            </div>
        </section>
    );
};

export default SearchList;

import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import components
import Logo from '../../img/logo.svg'
import LogoMobile from '../../img/logomobile.svg'
import Search from '../search/Search'
import HeaderMenuDrawer from './HeaderMenuDrawer'
import HeaderMenuMobileMotion from './HeaderMenuMobileMotion'
import { AuthContext } from "../../context/authContext";

const Header = () => {
  const [isFixed, setIsFixed] = useState(false);
  const location = useLocation();
  const pathUrl = location.pathname.split("/")[1];
  const { setNext } = useContext(AuthContext);

  useEffect(() => {
    if (pathUrl === "post") {
      setIsFixed(true);
    } else {
      setIsFixed(false)
    }
  }, [pathUrl])

  return (
    <div className={`${isFixed ? 'h-20' : 'h-20'} bg-tertiary text-neutral-800 flex items-center w-full`}>
      <div className='container h-full mx-auto flex items-center justify-between relative'>
        {/* nav drawer mobile*/}
        <div className='md:hidden relative z-50'>
          <HeaderMenuMobileMotion />
        </div>
        {/* nav drawer desktop*/}
        <div className='hidden md:block relative z-10'>
          <HeaderMenuDrawer />
        </div>
        {/* logo */}
        <div className="">
          <Link to="/" onClick={() => setNext(4)}>
            <img src={Logo} alt='Logo' className='hidden sm:block sm:h-12' />
          </Link>
          <Link to="/" onClick={() => setNext(4)}>
            <img src={Logo} alt='Logo' className='sm:hidden' />
          </Link>
        </div>
        {/*search*/}
        <div className='text-neutral-800'>
          <Search />
        </div>
      </div >
    </div >
  )
}

export default Header

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Avatar from '@mui/material/Avatar';


const PostAuthor = (props) => {
  const { author } = props;
  const [authorStories, setAuthorStories] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/authors/${author.header_author}`,
          { withCredentials: true });
        setAuthorStories(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [author]);

  /* console.log(header); */
  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent
  }

  return (
    <div className={`${author?.author_name ? 'block' : 'hidden'} w-full px-4 sm:px-6 py-8 mb-8 border border-[#cecece] border-t-accent border-t-[4px] mt-12`}>
      <h2 className="font-bold text-neutral-800 text-xl mb-4">Author: {author?.author_name}, {author?.author_role}</h2>
      <div className="md:hidden flex justify-center mb-6">
        <Avatar alt="avatar" src={`https://res.cloudinary.com/dfjtdwtka/image/upload/v${author?.author_img}`} sx={{ width: 72, height: 72 }} />
      </div>
      <div className="flex justify-between gap-2 mb-4">
        <p className="text-sm sm:text-base text-neutral-700">{author?.author_bio}</p>
        <div className="hidden md:block">
          <Avatar alt="avatar" src={`https://res.cloudinary.com/dfjtdwtka/image/upload/v${author?.author_img}`} sx={{ width: 72, height: 72 }} />
        </div>
      </div>
      <div>
        <h2 className="font-bold text-neutral-800 mb-4">Recent stories by {author?.author_name}:</h2>
        <ul className="flex flex-col list-disc list-outside text-[#1b7ea1] px-4">
          {
            authorStories.slice(0, 3).map(item => {
              return (
                <li key={item.id} className="mb-2 marker:text-neutral-800">
                  <Link to={`/post/${item.id}`}>{item.header_title}</Link>
                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  );
};


export default PostAuthor;

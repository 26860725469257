import React, { useEffect, useState, useRef } from 'react';
import Scroll from './Scroll';
import SearchList from './SearchList';
import axios from "axios";
import { BsSearch } from 'react-icons/bs';
import CloseIcon from '@mui/icons-material/Close';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(setActive, ref) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setActive(false)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const Search = () => {
  const [active, setActive] = useState(false)
  const [query, setQuery] = useState("");
  const [search, setSearch] = useState([]);
  const [queryNotFound, setQueryNotFound] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/search?q=${query}`,
        { withCredentials: true });
      setSearch(res.data);
      if (res.data[0].length === 0 && res.data[1].length === 0) {
        setQueryNotFound(true)
      }
      else setQueryNotFound(false)
    };
    if (query.length >= 3) fetchData();
  }, [query]);

  const wrapperRef = useRef(null);
  const inputElement = useRef(null);
  useOutsideAlerter(setActive, wrapperRef);

  function toggleSearchBar() {
    setActive(prevActive => !prevActive)
    /* inputElement.current.value = "" */
    setSearch([])
  }

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'initial'
    }
  }, [active])

  useEffect(() => {
    if (query.length === 0) {
      setSearch([])
    }
  }, [query])

  return (
    <div className='hidden sm:block'>
      <button onClick={toggleSearchBar}>
        <BsSearch />
      </button>
      <div ref={active ? wrapperRef : null} className={`search-bar  flex flex-col ${active ? 'active' : null}`} data-search-bar>
        <div className='container px-1 h-fit max-w-[820px] bg-white mx-auto'>
          <div className="flex flex-col w-full mt-6">
            <div className='flex justify-end items-start mb-12'>
              <button onClick={toggleSearchBar} className={`text-neutral-800 top-1/4 sm:top-1/2 ${active ? 'active' : null}`} aria-label="close search bar" data-search-toggler>
                <CloseIcon sx={{ fontSize: '32px' }} />
              </button>
            </div>
            <p className="text-[#4a4a4a] font-extrabold mb-4">Type your search and hit enter</p>
            <div className='pb-8 border border-t-0 border-r-0 border-l-0 border-b-neutral-400'>
              <input
                onChange={(e) => setQuery(e.target.value.toLowerCase())}
                ref={inputElement => { if (inputElement) { inputElement.focus(); } }}
                type="search"
                name="search"
                placeholder="Search"
                className="input-field border-0 outline-none text-[2.5rem] font-extrabold"
              />
            </div>
          </div>
        </div>
        <div className='mt-16'>
          <SearchList search={search} setActive={setActive} queryNotFound={queryNotFound} />
        </div>
      </div>
      <div className={`overlay ${active ? 'active' : null}`} data-overlay data-search-toggler>
      </div>
    </div>
  );
};

export default Search;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import DOMPurify from "dompurify";
import PostHeadline from './PostHeadline';
import PostProducts from "./PostProducts";
import PostParagraph from "./PostParagraph";
import PostAuthor from "./PostAuthor";
import noImgAvailable from "../../img/no_img_available.jpg";
import ModalDelete from "../../components/ui/ModalDelete";
import SkyscraperBanner from "../../components/banner/SkyscraperBanner";
import BackTopBtn from '../../components/ui/BackTopBtn';

const Post = () => {
  const [posts, setPosts] = useState([]);
  const [header, setHeader] = useState({});
  const [products, setProducts] = useState([]);
  const [paragraphs, setParagraphs] = useState([]);

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const location = useLocation();
  const navigate = useNavigate();

  const postId = location.pathname.split("/")[2];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/posts/${postId}`,
          { withCredentials: true });
        setPosts(res.data);
        setHeader(res.data[0][0])
        setProducts(res.data[1]);
        setParagraphs(res.data[2])
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [postId]);

  const handleDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/posts/${postId}`, { withCredentials: true });
      setOpen(false)
      navigate("/")
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
    }
  }

  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent
  }

  return (
    <div id={`post${header.id}`} className="w-full mt-24 md:mt-0">
      <div className="container mx-auto">
        <p className="hidden sm:block text-xs text-neutral-500 text-center font-semibold my-2">When you purchase through links in our articles, we may earn a small commission.</p>
        <PostHeadline item={header} posts={posts} setOpen={setOpen} />
        <div className="relative flex gap-8 justify-between items-start mt-6">
          <main className="relative flex flex-col md:gap-2 w-full md:w-8/12 mb-12">
            {
              header.header_img ?
                <img className="w-full md:h-[380px] mx-auto object-cover" src={`https://res.cloudinary.com/dfjtdwtka/image/upload/v${header?.header_img}`} alt="" />
                :
                <img className="w-full md:h-[380px] mx-auto object-cover" src={noImgAvailable} alt="" />
            }
            <p className="text-xs text-neutral-600 mt-2 leading-4">{header.header_img_credit}</p>
            <hr className="border-neutral-400 mt-4 md:mt-2" />
            <div className="md:px-20">
              <p
                className="text-base sm:text-[20px] text-[#111111] mt-4 sm:mt-6 md:mt-8"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(header.header_text),
                }}>
              </p>
              <h2 className="text-[24px] sm:text-[32px] text-[#111111] font-medium leading-9 mt-8">{header.header_subtitle}</h2>
              <PostProducts products={products} setProducts={setProducts} />
              <PostParagraph paragraphs={paragraphs} setParagraphs={setParagraphs} />
              <PostAuthor author={header} />
            </div>
            <BackTopBtn />
          </main>
          <SkyscraperBanner isPost={posts} />
          {/* <Menu cat={header.cat} /> */}
        </div>
      </div>
      <p className="sm:hidden px-4 text-xs text-neutral-500 text-center font-semibold mb-6">When you purchase through links in our articles, we may earn a small commission.</p>
      <ModalDelete open={open} handleDelete={handleDelete} handleClose={handleClose} title={header.header_title}>
        post
      </ModalDelete>
    </div>
  );
};

export default Post;

import React, { useState, useRef } from "react";
import avatar from '../../img/avatar.png';
import SmallOutlinedButton from "../../components/ui/buttons/SmallOutlinedButton"
const imageMimeType = /image\/(png|jpg|jpeg)/i;

const SettingsUsersEdit = (props) => {
  const {
    user,
    setUser,
    handleOpen
  } = props;

  const handleFormChange = (event, index) => {
    let data = [...user];
    data[index][event.target.name] = event.target.value;
    setUser(data);
  }

  return (
    user?.map((item, index) => {
      return (
        <div key={item.id || index + 1} className="w-full flex">
          <div className="flex flex-col gap-1 items-center justify-center w-full px-8 py-4 border border-[#cecece]">
            <input
              name="username"
              className="w-2/3 p-1 border border-neutral-300 text-sm text-neutral-800"
              type="text"
              placeholder="Username"
              defaultValue={item.username || ""}
              onChange={(event) => handleFormChange(event, index)}
            />
            <input
              name="email"
              className="w-2/3 p-1 border border-neutral-300 text-sm text-neutral-800"
              type="text"
              placeholder="Email"
              defaultValue={item.email || ""}
              onChange={(event) => handleFormChange(event, index)}
            />
            <input
              name="password"
              className="w-2/3 p-1 border border-neutral-300 text-sm text-neutral-800"
              type="text"
              placeholder="Password"
              defaultValue={item?.password || ""}
              onChange={(event) => handleFormChange(event, index)}
            />
            <div className="w-full flex justify-center mt-2">
              <SmallOutlinedButton /* className="border border-neutral-400 px-2 py-1" */ onClick={() => handleOpen(index, item?.username, item?.user_img)}>Delete User</SmallOutlinedButton>
            </div>
          </div>
        </div>
      )
    })
  );
};

export default SettingsUsersEdit;

import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import banner from '../../img/brand-banner.jpg';
import BackTopBtn from '../ui/BackTopBtn';

const SkyscraperBanner = () => {
    const [isPost, setIsPost] = useState(false);
    const location = useLocation();
    const pathUrl = location.pathname.split("/")[1];

    useEffect(() => {
        if (pathUrl === "post") {
            setIsPost(true);
        } else {
            setIsPost(false)
        }
    }, [pathUrl])

    const [sidebarWidth, setSidebarWidth] = useState(undefined);
    const [sidebarTop, setSidebarTop] = useState(undefined);

    useEffect(() => {
        const sidebarEl = document.querySelector('.sidebar').getBoundingClientRect();
        setSidebarWidth(sidebarEl.width);
        setSidebarTop(sidebarEl.top);
    }, []);

    useEffect(() => {
        if (!sidebarTop) return;

        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    }, [sidebarTop]);

    const isSticky = (e) => {
        const sidebarEl = document.querySelector('.sidebar');
        const scrollTop = window.scrollY;
        if (scrollTop >= sidebarTop - 10) {
            sidebarEl.classList.add('is-sticky');
        } else {
            sidebarEl.classList.remove('is-sticky');
        }
    }
    return (
        <div className={`${isPost ? 'top-[8rem]' : 'top-[3rem]'} sidebar hidden md:block mb-12`} >
            <img src={banner} alt="banner" className='w-[300px]' />
        </div>
    );
};

export default SkyscraperBanner;

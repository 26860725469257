import * as React from 'react'

// import Link
import { HashLink } from 'react-router-hash-link';

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    borderBottom: '1px solid grey',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,

}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: 'lightgrey' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .8)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    border: 0,
    backgroundColor: 'rgba(0, 0, 0, .8)'
}));

const scrollToTop = () => {
    window.scrollTo(0, 0);
}

export default function FooterAccordion(props) {
    const { data } = props
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div>
            {data.map((item, index) => {
                return (
                    <Accordion key={index} onChange={handleChange(`panel` + `${index}`)}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography className='text-neutral-300'>{item.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ul className='flex flex-col text-[15px]'>
                                {item.menu.map((item, index) => {
                                    return (
                                        <Typography key={index}>
                                            <li className='text-neutral-300 hover:text-accent-hover cursor-pointer md:text-sm px-6 mb-4' key={index}>
                                                <HashLink
                                                    to={`${item.href}#top`}
                                                    className='transition-all duration-300 capitalize'
                                                >
                                                    {item.name}
                                                </HashLink>
                                            </li>
                                        </Typography>
                                    )
                                })}
                            </ul>
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </div>
    );
}
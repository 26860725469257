import React, { useState } from 'react';
import Box from '@mui/material/Box';
import ContainedButton from '../../components/ui/buttons/ContainedButton';
import OutlinedButton from '../../components/ui/buttons/OutlinedButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export default function ModalPopUp(props) {
    const { children, title, open, handleDelete, handleClose } = props;
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        paddingY: 8,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
    };
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <p className='text-center'>
                    Are you sure you want to permanently DELETE this {children}?
                </p>
                <p className='text-[#000] font-bold text-2xl text-center'>
                    "{title === "" ? 'Blank Form' : title}"
                </p>
                <Box className='flex justify-evenly mt-6'>
                    <ContainedButton onClick={handleDelete}><DeleteForeverIcon /> Delete</ContainedButton>
                    <OutlinedButton onClick={handleClose}>Cancel</OutlinedButton>
                </Box>
            </Box>
        </Modal >
    );
}
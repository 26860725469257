import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import Login from "../ui/Login";
import LogoutIcon from '@mui/icons-material/Logout';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import SettingsIcon from '@mui/icons-material/Settings';

const HeaderAdmin = () => {
  const { currentUser, logout } = useContext(AuthContext);
  const { pathname } = useLocation();
  //refresh write form on button click
  function refreshPage() {
    if (pathname === "/write") {
      window.location.reload(false);
    }
  }

  return (
    <div className="w-full h-12 hidden lg:flex items-center bg-neutral-300">
      {!currentUser ? <Login />
        : (
          <div className="container mx-auto flex items-center justify-center">
            <div className="w-1/3 h-12 flex items-center justify-start gap-6">
              {currentUser ? (
                <>
                  <div>
                    <Link className="flex items-center gap-1 bg-neutral-100 text-neutral-800 border border-neutral-400 rounded py-1 px-2 hover:text-neutral-600 hover:border-neutral-600" to="/settings">
                      <SettingsIcon /> Settings
                    </Link>
                  </div>
                  <div>
                    <Link onClick={refreshPage} className="flex items-center gap-1 bg-neutral-100 text-neutral-800 border border-neutral-400 rounded py-1 px-2 hover:text-neutral-600 hover:border-neutral-600" to="/write">
                      <HistoryEduIcon /> New Post
                    </Link>
                  </div>
                </>
              ) : null}
            </div>
            <div className="w-1/3 h-12"></div>
            <div className="w-1/3 h-12 flex items-center justify-end gap-12">
              {currentUser ? (
                <span className="flex items-center gap-1 text-neutral-800">Welcome, <em><b>{currentUser?.username}</b></em></span>
              ) : null}
              <Link className="text-neutral-800 hover:text-neutral-600" onClick={logout} to="/"><LogoutIcon /> Logout</Link>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default HeaderAdmin

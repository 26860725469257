import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Register from "../ui/Register";

const Login = () => {
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });
  const [err, setError] = useState(null);

  const navigate = useNavigate();

  const { login, isRegister, toggleRegister } = useContext(AuthContext);

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(inputs)
      navigate("/");
    } catch (err) {
      setError(err.response.data);
    }
  };

  return (
    <div className="w-full">
      {
        isRegister ? <Register />
          :
          <div className="container mx-auto flex items-center">
            <form className="w-full flex items-center justify-between">
              <div className="flex gap-6">
                <input
                  required
                  type="text"
                  placeholder="username"
                  name="username"
                  className="pl-2"
                  onChange={handleChange}
                />
                <input
                  required
                  type="password"
                  placeholder="password"
                  name="password"
                  className="pl-2"
                  onChange={handleChange}
                />
                <button className="hover:text-white border border-neutral-400 hover:border-neutral-100 rounded px-2" onClick={handleSubmit}><PowerSettingsNewIcon /> Login</button>
              </div>

              {err && <p>{err}</p>}
              {/* <Link onClick={toggleRegister} className="hidden md:flex items-center gap-2 hover:text-white"><PersonAddIcon /> Add New User</Link>
              <Link onClick={toggleRegister} className="flex md:hidden items-center gap-2"><PersonAddIcon /></Link> */}
            </form>
          </div>
      }
    </div>
  );
};

export default Login;

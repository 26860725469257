import React from "react";
import DOMPurify from "dompurify";
import ContainedButton from "../../components/ui/buttons/ContainedButton";
import noImgAvailable from "../../img/no_img_available.jpg";

const PostProducts = (props) => {
  const { products } = props;

  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent
  }

  return (
    <>
      {products.map((item, index) => (
        <div key={item.id} id={`product${item.id}`} className="w-full flex flex-col gap-4">
          <div className="h-[2px] bg-[#cecece] my-4"></div>
          <h1 className="text-[20px] sm:text-[24px] text-[#111111] font-semibold">{index + 1 + '. ' + item?.product_name + ' '} <span className={item.product_keyword ? 'inline-block' : 'hidden'}>-</span> {item?.product_keyword}</h1>
          <div className="w-full mt-4 mb-2">
            {
              item.product_img ?
                <img className="w-full object-cover mb-2" src={`https://res.cloudinary.com/dfjtdwtka/image/upload/v${item?.product_img}`} alt="" />
                :
                <img className="w-full object-cover mb-2" src={noImgAvailable} alt="" />
            }
            <p className="text-xs text-neutral-600">{item?.product_img_credit}</p>
          </div>
          <div className="flex flex-wrap justify-center border border-[#cecece] rounded-md text-[18px] text-[#111111] mb-4 pb-4">
            <div className="w-full flex flex-col sm:flex-row px-4 sm:px-0 justify-between">
              <div className="w-full flex p-4">
                <ul className="w-full list-disc text-base sm:text-[20px]">
                  <h2 className="text-[#111111] font-bold mb-4">Pros</h2>
                  <div className="flex flex-col gap-1 ml-4">
                    <li className={item.product_pros_01 ? 'list-item' : 'hidden'}>{item?.product_pros_01}</li>
                    <li className={item.product_pros_02 ? 'list-item' : 'hidden'}>{item?.product_pros_02}</li>
                    <li className={item.product_pros_03 ? 'list-item' : 'hidden'}>{item?.product_pros_03}</li>
                  </div>
                </ul>
              </div>
              <div className="w-full flex p-4 text-base sm:text-[20px]">
                <ul className="w-full list-disc">
                  <h2 className="text-[#111111] font-bold mb-4">Cons</h2>
                  <div className="flex flex-col gap-1 ml-4">
                    <li className={item.product_cons_01 ? 'list-item' : 'hidden'}>{item?.product_cons_01}</li>
                    <li className={item.product_cons_02 ? 'list-item' : 'hidden'}>{item?.product_cons_02}</li>
                    <li className={item.product_cons_03 ? 'list-item' : 'hidden'}>{item?.product_cons_03}</li>
                  </div>
                </ul>
              </div>
            </div>
            <div className="mt-4">
              <ContainedButton
              /* to={item.product_url}
              className="text-accent leading-8 underline underline-offset-2 mb-4" */
              >
                {item.product_url_placeholder}
              </ContainedButton>
            </div>
          </div>
          <p
            className="text-base sm:text-[20px] text-[#111111] mb-4"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(item.product_text),
            }}>
          </p>
        </div >
      ))}
    </>

  );
};


export default PostProducts;

import axios from "axios";
import { createContext, useEffect, useState } from "react";
export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  const login = async (inputs) => {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`,
      inputs, {
      /*  headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': true,
        'Access-Control-Allow-Credentials': true
      } */withCredentials: true
    });
    setCurrentUser(res.data);
  };

  const logout = async (inputs) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/auth/logout`, {
      headers: {
        /*         'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://127.0.0.1:3000',
                'Access-Control-Allow-Headers': true,
                'Access-Control-Allow-Credentials': true */
      }, withCredentials: true
    });
    setCurrentUser(null);
  };

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);

  const [isRegister, setIsRegister] = useState(false);

  function toggleRegister() {
    setIsRegister(prev => !prev)
  }

  const postsPerColumn = 4;
  const [next, setNext] = useState(postsPerColumn);

  return (
    <AuthContext.Provider value={{ currentUser, login, logout, isRegister, toggleRegister, next, setNext, postsPerColumn }}>
      {children}
    </AuthContext.Provider>
  );
};

import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Close from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import FaceIcon from '@mui/icons-material/Face';
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/authContext";

import { categories } from '../../data'

export default function HeaderMenuDrawer() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { currentUser, logout, setNext } = useContext(AuthContext);
  return (
    <>
      <button onClick={() => setIsDrawerOpen(true)} className='flex items-center gap-2 text-neutral-800 hover:text-black border border-neutral-400 rounded p-1' href='#'>
        MENU
        <MenuIcon />
      </button>
      <Drawer
        anchor='left'
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box
          role="presentation"
          width='25vw'
          onClick={() => setIsDrawerOpen(false)}
          onKeyDown={() => setIsDrawerOpen(false)}
        >
          <List>
            <Close onClick={() => setIsDrawerOpen(false)} className='text-neutral-800 float-right mr-4 mt-4 mb-8 cursor-pointer' />
            {categories.map((item, index) => (
              <Link key={index} to={`/${item.href}`} onClick={() => setNext(4)}>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemText
                      primary={item.name}
                      primaryTypographyProps={{
                        fontWeight: 'bold',
                        letterSpacing: 1
                      }}
                      className='text-neutral-800 uppercase ml-6 cursor-pointer'
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </List>
          <Divider />
          <List>
            {
              process.env.REACT_APP_NODE_ENV === 'development' ? (
                currentUser ? (
                  <>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemText
                          primary={`${currentUser?.username}`}
                          primaryTypographyProps={{
                            fontWeight: 'bold',
                            letterSpacing: 1
                          }}
                          className='text-neutral-800 uppercase ml-6 cursor-pointer'
                        />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <Link onClick={logout} to="/">
                          <ListItemText
                            primary='Logout'
                            primaryTypographyProps={{
                              fontWeight: 'bold',
                              letterSpacing: 1
                            }}
                            className='text-neutral-800 uppercase ml-6 cursor-pointer'
                          />
                        </Link>
                      </ListItemButton>
                    </ListItem>
                  </>
                ) : (
                  <ListItem disablePadding>
                    <ListItemButton>
                      <Link to="/login">
                        <ListItemText
                          primary='Login'
                          primaryTypographyProps={{
                            fontWeight: 'bold',
                            letterSpacing: 1
                          }}
                          className='text-neutral-800 uppercase ml-6 cursor-pointer'
                        />
                      </Link>
                    </ListItemButton>
                  </ListItem>
                )) : null
            }
          </List>
        </Box>
      </Drawer>
    </>
  );
}
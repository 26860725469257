import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const ColorButton = styled(Button)({
    textTransform: 'none',
    color: '#FF2E4B',
    border: '1px solid #FF2E4B',
    '&:hover': {
        color: '#FF2E4B',
        backgroundColor: '#fff',
        border: '1px solid #FF2E4B',
    },
})

const SmallOutlinedButton = ({ onClick, children }) => {
    return (
        <ColorButton onClick={onClick} size="small" variant="outlined" disableElevation>{children}</ColorButton>
    );
}

export default SmallOutlinedButton;
import React from 'react'
import { Link } from "react-router-dom"

//import components
import HomeHeroCard from './HomeHeroCard'

const HomeHero = (props) => {
  const { posts, author, setAuthor } = props

  const homeHero01 = posts?.filter(item => {
    return item.home_hero_01 === 1
  })

  const homeHero02 = posts?.filter(item => {
    return item.home_hero_02 === 1
  })

  const homeHero03 = posts?.filter(item => {
    return item.home_hero_03 === 1
  })

  return (
    <main id='home' className='flex items-center bg-white lg:bg-cover lg:bg-center lg:bg-no-repeat py-6'>
      {/* XS sized Hero Cards for mobiles */}
      <div className='container flex sm:hidden flex-col md:flex-row gap-4 mx-auto lg:h-96'>
        <div className='flex items-center justify-center overflow-hidden min-h-52 sm:h-72 md:h-full w-full md:w-2/3 rounded-2xl text-xl sm:text-3xl md:text-4xl group drop-shadow'>
          {
            homeHero01 ? (
              homeHero01.map(item => (
                <Link key={item.id} className="w-full h-full" to={`/post/${item.id}`}>
                  <HomeHeroCard item={item} />
                </Link>
              ))) : null
          }
        </div>
        <div className='flex flex-col gap-4 items-center h-full w-full md:w-1/3'>
          <div className='flex items-center justify-center overflow-hidden min-h-52 sm:h-72 md:h-1/2 w-full rounded-2xl text-xl sm:text-3xl md:text-xl group drop-shadow'>
            {
              homeHero02 ? (
                homeHero02.map(item => (
                  <Link key={item.id} className="w-full h-full" to={`/post/${item.id}`}>
                    <HomeHeroCard item={item} />
                  </Link>
                ))) : null
            }
          </div>
          <div className='flex items-center justify-center overflow-hidden min-h-52 sm:h-72 md:h-1/2 w-full rounded-2xl text-xl sm:text-3xl md:text-xl group drop-shadow'>
            {
              homeHero03 ? (
                homeHero03.map(item => (
                  <Link key={item.id} className="w-full h-full" to={`/post/${item.id}`}>
                    <HomeHeroCard item={item} />
                  </Link>
                ))) : null
            }
          </div>
        </div>
      </div>
      {/* SM, LG and XL sized Hero Cards for tablets and desktop */}
      <div className='container hidden sm:flex flex-col md:flex-row gap-2 mx-auto lg:h-96'>
        <div className='relative flex items-center justify-center overflow-hidden h-52 sm:h-72 md:h-full w-full md:w-2/3 rounded-2xl text-xl sm:text-3xl md:text-4xl group'>
          {
            homeHero01 ? (
              homeHero01.map(item => (
                <Link key={item.id} className="w-full h-full" to={`/post/${item.id}`}>
                  <HomeHeroCard item={item} />
                </Link>
              ))) : null
          }
        </div>
        <div className='flex flex-col gap-2 items-center h-full w-full md:w-1/3'>
          <div className='relative flex items-center justify-center overflow-hidden h-52 sm:h-72 md:h-1/2 w-full rounded-2xl text-xl sm:text-3xl md:text-xl group'>
            {
              homeHero02 ? (
                homeHero02.map(item => (
                  <Link key={item.id} className="w-full h-full" to={`/post/${item.id}`}>
                    <HomeHeroCard item={item} />
                  </Link>
                ))) : null
            }
          </div>
          <div className='relative flex items-center justify-center overflow-hidden h-52 sm:h-72 md:h-1/2 w-full rounded-2xl text-xl sm:text-3xl md:text-xl group'>
            {
              homeHero03 ? (
                homeHero03.map(item => (
                  <Link key={item.id} className="w-full h-full" to={`/post/${item.id}`}>
                    <HomeHeroCard item={item} />
                  </Link>
                ))) : null
            }
          </div>
        </div>
      </div>

    </main >
  );
};

export default HomeHero;

import React, { useEffect, useState } from "react";
import axios from "axios";
import "react-quill/dist/quill.snow.css";
import Avatar from '@mui/material/Avatar';
import avatar from '../../img/avatar.png';


const WriteAuthor = (props) => {
  const { headerAuthor, setHeaderAuthor } = props;
  const [toggle, setToggle] = useState(false);
  const [author, setAuthor] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/authors`,
          { withCredentials: true });
        setAuthor(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const toggleAuthor = (index) => {
    setHeaderAuthor(index)
  }
  /* console.log(headerAuthor); */
  return (
    <div className="w-full p-2 border border-neutral-300">
      <h2 className="text-neutral-800 text-lg mb-2">Choose Author</h2>
      <div className="w-full flex-grow-2 flex  gap-1">
        {
          author.map((item, index) => {
            return (
              <div key={item.id} onClick={() => toggleAuthor(item.id)} className={`${headerAuthor === item.id ? 'bg-[#FF596F] border-[#FF596F]' : null} flex gap-2 w-1/2 p-2 border border-[#cecece] rounded-md group hover:border-[#FF596F]`}>

                {
                  item.author_img ?
                    <Avatar alt="avatar" src={`https://res.cloudinary.com/dfjtdwtka/image/upload/v${item?.author_img}`} sx={{ width: 32, height: 32 }} />
                    :
                    <img className="w-full" src={avatar} alt="" />
                }

                <div className="w-full flex flex-col gap-2">
                  <p className={`${headerAuthor === item.id ? 'text-white group-hover:text-white' : 'text-neutral-800 group-hover:text-[#FF596F]'} text-sm  font-bold`}>{item.author_name}</p>
                  <p className={`${headerAuthor === item.id ? 'text-white group-hover:text-white' : 'text-neutral-800 group-hover:text-[#FF596F]'} text-sm  italic`}>{item.author_role}</p>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
};

export default WriteAuthor;

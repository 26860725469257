import React from "react";
import DOMPurify from "dompurify";

const PostParagraph = (props) => {
  const { paragraphs } = props;

  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent
  }

  return (
    <>
      {
        paragraphs ?
          paragraphs.map(item => {
            return (
              <div key={item.id} className="w-full mt-4">
                <h2 className="text-[24px] sm:text-[32px] text-[#111111] font-semibold leading-8 mb-8">{item?.paragraph_title}</h2>
                <p
                  className="text-base sm:text-[20px] text-[#111111] mb-8"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(item?.paragraph_text),
                  }}
                ></p>
                {
                  item.paragraph_img ? (
                    <img className="w-full h-full object-cover mb-1" src={`https://res.cloudinary.com/dfjtdwtka/image/upload/v${item?.paragraph_img}`} alt="" />
                  ) : null
                }
                <p className="text-xs text-neutral-600 mt-2">{item?.paragraph_img_credit}</p>
              </div>
            )
          }) :
          null
      }
    </>
  );
};


export default PostParagraph;

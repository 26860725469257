import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { AuthContext } from "../../context/authContext";
import DOMPurify from "dompurify";
import Breadcrumbs from '../../components/ui/Breadcrumbs'
import Avatar from '@mui/material/Avatar';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const PostHeadline = (props) => {
  const { item, posts, setOpen } = props;
  const handleOpen = () => setOpen(true);
  const { currentUser } = useContext(AuthContext);

  return (
    <header className="flex flex-col mt-6">
      <div className="mb-4 sm:mb-10">
        <Breadcrumbs cat={item.cat} title={item.header_title} />
      </div>
      <p className="text-[14px] text-accent font-bold uppercase my-4">{item.cat}</p>
      <h1 className="text-[32px] sm:text-[40px] md:text-[52px] font-bold text-[#333] mb-3 sm:mb-6 leading-8 md:leading-tight">{item.header_title}</h1>
      <p
        className="text-[14px] sm:text-[18px] md:text-[24px] text-[#757575] text-justify"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(item.header_description),
        }}
      ></p>
      {process.env.REACT_APP_NODE_ENV === 'development' && currentUser && (
        <div className="w-full p-2 flex gap-4 my-6">
          <Link to={`/write?edit=${item.id}`} state={posts} className="flex items-center gap-1 text-neutral-800 bg-neutral-100 border border-neutral-400 rounded py-1 px-2 hover:text-neutral-600">
            <HistoryEduIcon /> Edit Post
          </Link>
          <Link onClick={handleOpen} className="flex items-center gap-1 text-accent bg-neutral-100 border border-neutral-400 rounded py-1 px-2 hover:text-red-500">
            <DeleteForeverIcon /> Delete Post
          </Link>
        </div>
      )}
      <div className="w-full flex justify-start gap-4 mt-4">
        <div className="hidden sm:flex h-[64px] w-[64px] max-h-full rounded-[64px] overflow-hidden items-center">
          <Avatar sx={{ width: 62, height: 62 }} src={`https://res.cloudinary.com/dfjtdwtka/image/upload/v${item?.author_img}`} alt={item?.author_name} />
        </div>
        <div className={`${item.author_name ? 'block' : 'hidden'} text-[14px] text-[#111111] sm:mt-4`}>
          <p>By <Link to={`/author/${item?.author_name}`} state={item} className="text-[#1b7ea1]">{item?.author_name}</Link> </p>
          <p>{item?.author_role}, Smart Home Gadgets | <span className="text-[#757575] uppercase">{moment(item.header_date).tz('Europe/Berlin').format('MMM Do YYYY, h:mm a z')}</span> </p>
        </div>
      </div>
    </header>
  );
};

export default PostHeadline;

import React, { useEffect, useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useLocation
} from "react-router-dom";
import ScrollToTop from "./hooks/ScrollToTop";
import Header from "./components/header/Header";
import HeaderMenu from "./components/header/HeaderMenu";
import HeaderAdmin from "./components/header/HeaderAdmin";
import Home from "./pages/home/Home";
import Post from "./pages/post/Post";
import Author from "./pages/author/Author";
import Write from "./pages/write/Write";
import Search from "./pages/search/Search";
import Settings from './pages/settings/Settings';
import Footer from "./components/footer/Footer";
import "./App.css";
import CookieConsent from "./components/ui/CookieConsent";

const Layout = () => {
  const [isFixed, setIsFixed] = useState(false);
  const [toggleAuth, setToggleAuth] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;
  const pathUrl = location.pathname.split("/")[1];

  useEffect(() => {
    if (pathUrl === "post") {
      setIsFixed(true);
    } else {
      setIsFixed(false)
    }
  }, [pathUrl])

  return (
    <div className='bg-white relative p-[0.05px]'>
      <ScrollToTop />
      <div className={`${isFixed ? 'fixed z-10' : 'static'} w-full`}>
        {/* admin menu sm - xl */}
        {process.env.REACT_APP_NODE_ENV === 'development' ? <HeaderAdmin /> : toggleAuth ? <HeaderAdmin /> : null}
        <Header />
      </div>
      {pathname === '/settings' ?
        null :
        <HeaderMenu />
      }
      <Outlet />
      <CookieConsent />
      <Footer setToggleAuth={setToggleAuth} />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/post/:id",
        element: <Post />,
      },
      {
        path: "/author/:id",
        element: <Author />,
      },
      {
        path: "/write",
        element: <Write />,
      },
      {
        path: "/search",
        element: <Search />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
    ]
  }
]);

function App() {
  return (
    <div className="app">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

import React from 'react'

// import logo
import Logo from '../../img/logo.svg'

// import social data
import { social, footer } from '../../data'

//import components
import FooterLinks from './FooterLinks'
import FooterAccordion from './FooterAccordion'

const Footer = (props) => {
  const { setToggleAuth } = props;
  const year = new Date().getFullYear();
  return (
    <footer className='bg-neutral-800 pb-6 md:pt-8'>
      <div className='container mx-auto md:w-3/4'>
        <div className='flex flex-col lg:flex-row py-6 space-y-6 lg:space-y-0 items-center justify-between md:border-b border-neutral-400'>
          <div className='flex flex-col md:flex-row justify-start gap-4 md:gap-8'>
            <div className='mx-auto md:mx-0 w-fit'>
              <img src={Logo} alt='' />
            </div>
            <div className='flex justify-center md:justify-start w-full md:w-2/5'>
              <p className='text-xs text-center md:text-left text-neutral-400'>Smart Home Gadgets helps you find your tech sweet spot. We steer you to products you'll love and show you how to get the most out of them.</p>
            </div>
          </div>
          <div className='flex space-x-6 items-center justify-center'>
            {social.map((item, index) => {
              const { href, icon } = item
              return (
                <a className='text-accent text-lg' href={href} key={index}>
                  {icon}
                </a>
              )
            })}
          </div>
        </div>
        <div className='hidden md:flex w-full py-8'>
          <FooterLinks data={footer} />
        </div>
        <div className='w-full md:hidden mb-6'>
          <FooterAccordion data={footer} />
        </div>
        <div className='w-full text-xs text-center text-neutral-400'>
          <p onClick={() => setToggleAuth(prev => !prev)}>
            Copyright <span>&#169;</span> {year} Smart Home Gadgets
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer

import React from 'react';
import AuthorPostCard from './AuthorPostCard';
import SkyscraperBanner from '../../components/banner/SkyscraperBanner';

const AuthorPostList = (props) => {
    const { posts } = props;

    return (
        <section className='flex items-start pt-0 sm:pt-4 sm:pb-[100px] bg-white min-h-[60vh]'>
            <div className={`${posts[0]?.author_name ? 'block' : 'hidden'} container flex flex-col items-start gap-1 mx-auto h-fit relative`}>
                <h2 className='text-neutral-800 sm:text-2xl font-semibold mb-2'>{`Articles by ${posts[0]?.author_name}`}</h2>
                <hr className='w-full border border-[#d8d8d8] mb-6' />
                <div className='w-full flex justify-between'>
                    <div className='h-full w-full md:w-2/3 mb-4'>
                        <AuthorPostCard posts={posts} />
                    </div>
                    <SkyscraperBanner isPost={posts} />
                </div>
            </div>
        </section>
    );
};

export default AuthorPostList;

import React, { useEffect, useState } from "react";
import axios from "axios";
import OutlinedButton from "../../components/ui/buttons/OutlinedButton"
import ContainedButton from "../../components/ui/buttons/ContainedButton"
import SettingsUsersEdit from "./SettingsUsersEdit";
import ModalDelete from "../../components/ui/ModalDelete";
import _ from "lodash";

const SettingsUsers = () => {
  const [user, setUser] = useState([
    {
      username: '',
      email: '',
      password: ''
    }]);
  const [userBackup, setUserBackup] = useState([
    {
      username: '',
      email: '',
      password: ''
    }]);
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/users`,
          { withCredentials: true });
        let cloneRes01 = _.cloneDeep(res.data)
        let cloneRes02 = _.cloneDeep(res.data)
        if (res) {
          setUser(cloneRes01);
          setUserBackup(cloneRes02);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const [open, setOpen] = useState(false);
  const [modalFields, setModalFields] = useState({})
  const handleOpen = (index, title, img) => {
    /* console.log('index :::', index);
    console.log('title :::', title);
    console.log('img :::', img); */
    setModalFields({ index, title, img })
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false)
  }

  const toggleModal = (event) => {
    event.preventDefault()
    if (isModal) {
      setUser(userBackup)
    }
    setIsModal(prev => !prev)
  }

  const addFields = () => {
    let object = {
      username: '',
      email: '',
      password: ''
    }
    setUser([...user, object])
  }

  const removeFields = (index) => {
    let data = [...user]
    data.splice(index, 1)
    setUser(data)
    setOpen(false)
  }

  //submit form
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`,
        user);
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
    }
    setOpen(false)
  };
  console.log('user :::', user);
  return (
    <div className="w-5/6 mx-auto mb-12">
      <div className="w-full flex items-center justify-between py-2">
        <h2 className="w-1/3 text-xl text-neutral-800">Users</h2>
        <div className="flex gap-4">
          {isModal ? (
            <>
              <OutlinedButton onClick={addFields}>+ Add User</OutlinedButton>
              <ContainedButton onClick={handleSubmit} >Update</ContainedButton>
            </>
          )
            : null}
          <OutlinedButton onClick={(e) => toggleModal(e)}>{isModal ? 'Cancel' : 'Edit Users'}</OutlinedButton>
        </div>
      </div>

      <div className="w-full flex flex-col gap-2">
        {!isModal ?
          user?.map((item, index) => {
            return (
              <div key={item.id || index} className="flex">
                <div className="flex flex-col items-center justify-center w-1/3 px-8 py-4 border border-[#cecece] border-r-0">
                  <p className="text-sm  text-neutral-800">Username: <span className="font-bold">{item?.username}</span></p>
                </div>
                <div className="flex flex-col gap-2 w-2/3 px-8 py-4 border border-[#cecece]">
                  <p className="text-sm text-neutral-800">Email: {item?.email}</p>
                </div>
              </div>
            )
          }) : (
            <SettingsUsersEdit
              user={user}
              setUser={setUser}
              handleOpen={handleOpen}
            />
          )}
      </div>

      <div className="w-full flex justify-start gap-4">
      </div>
      <ModalDelete open={open} handleDelete={() => removeFields(modalFields.index)} handleClose={handleClose} title={modalFields.title} >
        user
      </ModalDelete>
    </div>
  );
};

export default SettingsUsers;

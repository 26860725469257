import React from 'react';
import {
    Breadcrumbs as MUIBreadcrumbs,
    Link,
    Typography
} from '@mui/material';
import { useNavigate } from "react-router";

export default function Breadcrumbs(props) {
    const { cat, title } = props;
    const navigate = useNavigate();
    return (
        <div role="presentation" >
            <MUIBreadcrumbs aria-label="breadcrumb">
                <Link
                    className='text-[14px]'
                    underline="hover"
                    color="inherit"
                    onClick={() => navigate("/")}
                >
                    Home
                </Link>
                <Link
                    className='capitalize text-[14px]'
                    underline="hover"
                    color="inherit"
                    onClick={() => navigate(`/?cat=${cat}`)}
                >
                    {cat}
                </Link>
                <div className='text-[14px] text-[#111111] w-[260px] sm:w-[460px] overflow-hidden text-ellipsis whitespace-nowrap'>{title}</div>
            </MUIBreadcrumbs>
        </div>
    );
}
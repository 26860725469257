import React, { useRef, useState } from "react";
import ReactQuill from "react-quill";
import OutlinedButton from "../../components/ui/buttons/OutlinedButton";
import ModalDelete from "../../components/ui/ModalDelete";
import "react-quill/dist/quill.snow.css";
import uploadImg from '../../img/upload.jpg';
const imageMimeType = /image\/(png|jpg|jpeg)/i;

const WriteProduct = (props) => {
  const {
    productFields,
    setProductFields,
  } = props;

  /* console.log('productFields :::', productFields); */

  const [open, setOpen] = useState(false);
  const [modalFields, setModalFields] = useState({})
  const handleOpen = (index, title, img) => {
    /* console.log('index :::', index);
    console.log('title :::', title);
    console.log('img :::', img); */
    setModalFields({ index, title, img })
    setOpen(true);
  }
  const handleClose = () => setOpen(false);

  const productTextRef = useRef([]);
  const productImageRef = useRef([]);
  const [productFiles, setProductFiles] = useState([]);

  const productImgHandler = async (event, index, productId) => {
    let data = [...productFields];
    data[index]['product_img'] = event.target.files[0]
    setProductFields(data)

    let productImg = [...productFiles];
    productImg[index] = productImageRef.current[index].files[0];
    if (!productImg[index].type.match(imageMimeType)) {
      alert("Image mime type is not valid");
      return;
    }

    let fileReader = new FileReader();
    fileReader.readAsDataURL(productImg[index]);
    fileReader.onload = (e) => {
      const { result } = e.target;
      productFiles.push(result)
      for (let i = 0; i < productFields.length; i++) {
        if (productFields[i]['id'] === productId) {
          let imgId = `product-img${index}${productId}`
          document.getElementById(imgId).src = result
        }
      }
    }
  }

  const handleFormChange = (event, index) => {
    let data = [...productFields];
    data[index][event.target.name] = event.target.value;
    setProductFields(data);
  }

  const handleTextChange = (event, index) => {
    let data = [...productFields];
    if (productTextRef.current[index].value) {
      data[index].product_text = productTextRef.current[index].value;
    }
    setProductFields(data);
  }

  const addFields = () => {
    let object = {
      product_name: '',
      product_keyword: '',
      product_pros_01: '',
      product_pros_02: '',
      product_pros_03: '',
      product_cons_01: '',
      product_cons_02: '',
      product_cons_03: '',
      product_url_placeholder: '',
      product_url: '',
      product_text: '',
      product_img: '',
      product_img_credit: '',
      post_id: ''
    }
    setProductFields([...productFields, object])
  }

  const removeFields = (index) => {
    let data = [...productFields]
    data.splice(index, 1)
    setProductFields(data)
    setOpen(false)
  }

  return (
    <div className="container mx-auto">
      <h2 className="text-neutral-800 text-2xl mb-4 w-full">Add Product</h2>
      <form>
        {productFields.map((item, index) => (
          <div key={index} className="w-full mb-12">
            <h2 className="text-neutral-800 text-xl mb-4 w-full">{`Product ${index + 1}`}</h2>
            <div className="w-full flex gap-4 justify-between items-stretch">
              <div className="flex flex-col gap-4 w-3/4">
                <input
                  className="w-full p-1.5 border border-neutral-300"
                  name="product_name"
                  type="text"
                  placeholder="Product Name"
                  onChange={(event) => handleFormChange(event, index)}
                  defaultValue={item.product_name || ""}
                />
                <input
                  className="w-full p-1.5 border border-neutral-300"
                  name="product_keyword"
                  type="text"
                  placeholder="Product Keyword"
                  onChange={(event) => handleFormChange(event, index)}
                  defaultValue={item.product_keyword || ""}
                />
                <input
                  className="w-full p-1.5 border border-neutral-300"
                  name="product_pros_01"
                  type="text"
                  placeholder="Product Pros 01"
                  onChange={(event) => handleFormChange(event, index)}
                  defaultValue={item.product_pros_01 || ""}
                />
                <input
                  className="w-full p-1.5 border border-neutral-300"
                  name="product_pros_02"
                  type="text"
                  placeholder="Product Pros 02"
                  onChange={(event) => handleFormChange(event, index)}
                  defaultValue={item.product_pros_02 || ""}
                />
                <input
                  className="w-full p-1.5 border border-neutral-300"
                  name="product_pros_03"
                  type="text"
                  placeholder="Product Pros 03"
                  onChange={(event) => handleFormChange(event, index)}
                  defaultValue={item.product_pros_03 || ""}
                />
                <input
                  className="w-full p-1.5 border border-neutral-300"
                  name="product_cons_01"
                  type="text"
                  placeholder="Product Cons 01"
                  onChange={(event) => handleFormChange(event, index)}
                  defaultValue={item.product_cons_01 || ""}
                />
                <input
                  className="w-full p-1.5 border border-neutral-300"
                  name="product_cons_02"
                  type="text"
                  placeholder="Product Cons 02"
                  onChange={(event) => handleFormChange(event, index)}
                  defaultValue={item.product_cons_02 || ""}
                />
                <input
                  className="w-full p-1.5 border border-neutral-300"
                  name="product_cons_03"
                  type="text"
                  placeholder="Product Cons 03"
                  onChange={(event) => handleFormChange(event, index)}
                  defaultValue={item.product_cons_03 || ""}
                />
                <input
                  className="w-full p-1.5 border border-neutral-300"
                  name="product_url_placeholder"
                  type="text"
                  placeholder="Product URL Placeholder"
                  onChange={(event) => handleFormChange(event, index)}
                  defaultValue={item.product_url_placeholder || ""}
                />
                <input
                  className="w-full p-1.5 border border-neutral-300"
                  name="product_url"
                  type="text"
                  placeholder="Product URL"
                  onChange={(event) => handleFormChange(event, index)}
                  defaultValue={item.product_url || ""}
                />
                <div className="h-[200px] overflow-y-scroll border-b-[0.5px] border-t-[0.5px] border-neutral-300">
                  <ReactQuill
                    ref={item => (productTextRef.current[index] = item)}
                    className="product-text h-full border-none"
                    theme="snow"
                    value={item?.product_text}
                    defaultValue={item?.product_text}
                    onChange={(event) => handleTextChange(event, index)}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-between gap-4 w-1/4">
                <div className="flex flex-col flex-1 gap-2 border border-neutral-300 p-4">
                  <h2 className="text-neutral-800 text-2xl mb-4">{`Product ${index + 1} image`}</h2>
                  <div className="flex flex-col gap-4 mb-4">
                    <label className="text-sm" htmlFor='file'>Browse product image</label>

                    <input
                      ref={item => (productImageRef.current[index] = item)}
                      type="file"
                      id='file'
                      accept='.png, .jpg, .jpeg'
                      onChange={(event) => productImgHandler(event, index, item.id)}
                    />

                  </div>
                  <div className="flex justify-between w-full border border-neutral-300 mb-4">
                    {
                      item.product_img ?
                        <img className="w-full " id={"product-img" + index + item.id} src={`https://res.cloudinary.com/dfjtdwtka/image/upload/v${item.product_img}`} alt="preview" />
                        :
                        <img className="w-full" id={"product-img" + index + item.id} src={uploadImg} alt="" />
                    }
                  </div>
                  <input
                    className="w-full p-1.5 border border-neutral-300 mb-4"
                    name="product_img_credit"
                    type="text"
                    placeholder="Image Credit"
                    onChange={(event) => handleFormChange(event, index)}
                    defaultValue={item.product_img_credit || ""}
                  />
                  <OutlinedButton /* className="border border-neutral-400 px-2 py-1" */ onClick={() => handleOpen(index, item?.product_name, item?.product_img)}>Delete</OutlinedButton>
                </div>
              </div>
            </div>
          </div>
        ))}
      </form>
      <div className="w-full flex justify-start gap-4">
        <OutlinedButton /* className="border border-neutral-400 px-2 py-1" */ onClick={addFields}>+ Add Product</OutlinedButton>
      </div>
      <ModalDelete open={open} handleDelete={() => removeFields(modalFields.index)} handleClose={handleClose} title={modalFields.title} >
        product
      </ModalDelete>
    </div>
  );
};

export default WriteProduct;

import React, { useContext } from 'react';
import { AuthContext } from "../../context/authContext";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import navigation data
import { categories } from '../../data'

const HeaderCategories = () => {
  const cat = useLocation().search
  const { setNext } = useContext(AuthContext);



  let activeStyle = {
    color: "red",
  };

  return (
    <ul className='header-categories grid grid-flow-col auto-cols-[21%] capitalize overflow-x-scroll'>
      {categories.map((item, index) => {
        return (
          <li
            className='flex justify-center py-2 cursor-pointer font-medium text-xs xl:text-sm whitespace-nowrap'
            key={index}
          >
            <NavLink
              to={`/${item.href}`}
              className='text-[#111111] hover:text-accent-hover'
              style={() =>
                cat === item.href ? activeStyle : undefined
              }
              onClick={() => setNext(4)}
            >
              {item.name}
            </NavLink>
          </li>
        )
      })}
    </ul>
  )
}

export default HeaderCategories

import React, { Fragment } from 'react'
import Media from 'react-media'
import noImgAvailable from "../../img/no_img_available.jpg"

const HomeHeroCard = (props) => {
    const { item } = props

    return (
        <>
            <Media queries={{
                small: "(max-width: 639px)",
                medium: "(min-width: 640px)"
            }}>
                {matches => (
                    <Fragment>
                        {matches.small &&
                            <div div key={item?.id} className='flex flex-wrap gap-4 w-fit h-full'>
                                <div className='flex sm:hidden w-full h-1/2'>
                                    {
                                        item.header_img ?
                                            <img className="object-cover w-full h-full sm:flex-shrink-0 sm:min-h-full sm:min-w-full transition-all group-hover:scale-110" src={`https://res.cloudinary.com/dfjtdwtka/image/upload/v${item?.header_img}`} alt="" />
                                            :
                                            <img className="object-cover h-full sm:flex-shrink-0 sm:min-h-full sm:min-w-full transition-all group-hover:scale-110" src={noImgAvailable} alt="" />
                                    }
                                </div>
                                <div className='w-full flex flex-col gap-2 px-4'>
                                    <div className='bg-accent w-fit px-3 py-[2px]'>
                                        <h4 className='font-sans font-bold text-xs text-white capitalize'>{item?.cat}</h4>
                                    </div>
                                    <h2 className='font-bold text-black text-xl sm:hidden text-wrap'>{item?.header_title}</h2>
                                    <h2 className='font-bold text-white hidden sm:block '>{item?.header_title}</h2>
                                    <p className='font-bold text-xs text-black pb-4'>{`By ${item?.author_name}`}</p>
                                </div>
                            </div>
                        }
                        {matches.medium &&
                            <div key={item?.id} className='w-full h-full relative'>
                                <div className='h-[70%] w-full bg-gradient-to-t from-[#1112339a] to-transparent transition-all absolute bottom-0 z-20'></div>
                                {
                                    item.header_img ?
                                        <img className="object-cover h-full sm:flex-shrink-0 sm:min-h-full sm:min-w-full relative z-10 transition-all group-hover:scale-110" src={`https://res.cloudinary.com/dfjtdwtka/image/upload/v${item?.header_img}`} alt="" />
                                        :
                                        <img className="object-cover h-full sm:flex-shrink-0 sm:min-h-full sm:min-w-full relative z-10 transition-all group-hover:scale-110" src={noImgAvailable} alt="" />
                                }
                                <div className='w-full absolute bottom-0 z-30 flex flex-col gap-2 p-6'>
                                    <div className='bg-accent w-fit px-3 py-[2px]'>
                                        <h4 className='font-sans font-bold text-xs text-white capitalize'>{item?.cat}</h4>
                                    </div>
                                    <h2 className='font-bold text-white text-xl sm:hidden'>{item?.header_title}</h2>
                                    <h2 className='font-bold text-white hidden sm:block '>{item?.header_title}</h2>
                                    <p className='font-bold text-xs text-white'>{`By ${item?.author_name}`}</p>
                                </div>
                            </div>
                        }
                    </Fragment>
                )}
            </Media>
        </>
    );
};

export default HomeHeroCard

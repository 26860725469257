//  icons
import {
  FiYoutube,
  FiInstagram,
  FiGithub,
  FiDribbble,
  FiLayout,
  FiSettings,
  FiPenTool,
  FiTag,
  FiMail,
  FiMapPin,
} from 'react-icons/fi';
// social
export const social = [
  {
    icon: <FiYoutube />,
    href: '',
  },
  {
    icon: <FiInstagram />,
    href: '',
  },
  {
    icon: <FiGithub />,
    href: '',
  },
  {
    icon: <FiDribbble />,
    href: '',
  },
];

// categories
export const categories = [
  {
    name: 'home',
    href: '',
  },
  {
    name: 'wellbeing',
    href: '?cat=wellbeing',
  },
  {
    name: 'smart & cozy',
    href: '?cat=smart and cozy',
  },
  {
    name: 'kitchenware',
    href: '?cat=kitchenware',
  },
  {
    name: 'hobbies',
    href: '?cat=hobbies',
  },
  {
    name: 'garden',
    href: '?cat=garden',
  },
  {
    name: 'smart hubs',
    href: '?cat=smart hubs',
  },
  {
    name: 'home security',
    href: '?cat=home security',
  }
];

// navigation footer
export const footer = [
  {
    name: 'Policies',
    menu: [
      {
        name: 'Privacy Policy',
        href: 'privacy-policy',
      },
      {
        name: 'Cookie Policy',
        href: 'cookie-policy',
      },
    ]
  },
  {
    name: 'About',
    menu: [
      {
        name: 'About Us',
        href: 'about',
      },
      {
        name: 'Contact Us',
        href: 'contact',
      },
    ]
  },
  {
    name: 'Categories',
    menu: categories
  },
];

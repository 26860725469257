import React from 'react'
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
//import Slider 
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import navigation data
import { categories } from '../../data'

// import mui media query
import useMediaQuery from '@mui/material/useMediaQuery';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const HeaderMenuMobileSlider = () => {
  const matches = useMediaQuery('(max-width:640px)');
  const cat = useLocation().search

  let activeStyle = {
    color: "red",
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: matches ? 1 : 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  return (
    <div className='h-full w-full'>
      <ul className='h-full w-full text-[16px]'>
        <Slider className='h-full w-full' {...settings}>
          {categories.map((item, index) => {
            return (
              <li
                className='text-neutral-800 cursor-pointer text-center font-medium text-sm'
                key={index}
              >
                <NavLink
                  to={`/${item.href}`}
                  className='text-[#111111]'
                  style={() =>
                    cat === item.href ? activeStyle : undefined
                  }
                >
                  {item.name}
                </NavLink>
              </li>
            )
          })}
        </Slider>
      </ul>
    </div>
  )
}

export default HeaderMenuMobileSlider

import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
// import components
import HeaderCategories from './HeaderCategories'
import HeaderMenuMobileSlider from './HeaderMenuMobileSlider'
import Socials from '../ui/Socials'
import useMediaQuery from '@mui/material/useMediaQuery';

const HeaderMenu = () => {
  const [isFixed, setIsFixed] = useState(false);
  const location = useLocation();
  const pathUrl = location.pathname.split("/")[1];
  const matches = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    if (pathUrl === "post" && process.env.REACT_APP_NODE_ENV === 'development') {
      setIsFixed(true);
    } else {
      setIsFixed(false)
    }
  }, [pathUrl])

  return (
    <nav className={`${isFixed && matches ? 'mt-20' : isFixed && !matches ? 'mt-32' : pathUrl === "post" ? 'mt-20' : null} h-14 w-full hidden md:flex flex-col justify-center items-center`}>
      <div className='container mx-auto h-full flex items-center justify-between '>
        <div className=' text-accent font-bold text-sm'>
          {matches ? 'Choose Category' : 'EXPLORE >>>'}
        </div>
        {/* nav */}
        <div className='w-[60%] hidden md:block'>
          <HeaderCategories />
        </div>
        <div className='w-[120px] sm:w-3/6 h-6 md:hidden'>
          <HeaderMenuMobileSlider />
        </div>
        {/* Socials */}
        <div className='hidden lg:block'>
          <Socials />
        </div>
      </div>
      {/* <div className='w-2/3 border-b-tertiary border-b-2'></div> */}
    </nav>
  )
}

export default HeaderMenu

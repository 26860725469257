import React, { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import OutlinedButton from '../../components/ui/buttons/OutlinedButton';
import noImgAvailable from "../../img/no_img_available.jpg"
/* import ColorButton from '../../components/ui/ColorButton'; */

const imagePerRow = 4;

const HomePostCard = (props) => {
    const { posts } = props;

    const [next, setNext] = useState(imagePerRow);
    const handleMoreImage = () => {
        setNext(next + imagePerRow);
    };

    const getText = (html) => {
        const doc = new DOMParser().parseFromString(html, "text/html")
        return doc.body.textContent
    }

    return (
        <>
            {posts?.slice(0, next)?.map((item) => {
                return (
                    <div key={item.id} className='h-fit w-full md:w-[95%] py-4 border-b border-[#dedede] group'>
                        <Link className="link" state={posts} to={`/post/${item.id}`}>
                            <div className='flex md:flex-row-reverse h-fit w-full'>
                                <div className='h-fit w-4/5 md:ml-4'>
                                    <h4 className='text-accent font-bold capitalize'>{item?.cat}</h4>
                                    <h2 className='text-neutral-800 group-hover:text-sky-600 font-bold text-base sm:text-2xl mb-2 sm:mb-0'>{item?.header_title}</h2>
                                    <p className='hidden md:block text-[#757575] font-medium mb-6'>{getText(item?.header_description)}</p>
                                    <div className="text-[#959595] font-semibold text-xs flex gap-3">
                                        <p>By {item?.author_name}</p>
                                        <p className="hidden sm:block">{moment(item?.header_date).fromNow()}</p>
                                    </div>
                                </div>
                                <div className='h-16 sm:h-24 md:h-28 border border-neutral-300 flex items-center'>
                                    {
                                        item.header_img ?
                                            <img className="h-full w-auto" src={`https://res.cloudinary.com/dfjtdwtka/image/upload/v${item?.header_img}`} alt="" />
                                            :
                                            <img className="h-full w-auto" src={noImgAvailable} alt="" />
                                    }
                                </div>
                            </div>
                        </Link>
                    </div>
                )
            })}
            {next < posts?.length && (
                <div className="flex justify-center my-16">
                    <OutlinedButton
                        onClick={handleMoreImage}
                    >
                        MORE STORIES
                    </OutlinedButton>
                </div>
            )}
        </>
    );
};

export default HomePostCard;

import React, { useState, useRef } from "react";
import avatar from '../../img/avatar.png';
import SmallOutlinedButton from "../../components/ui/buttons/SmallOutlinedButton"
const imageMimeType = /image\/(png|jpg|jpeg)/i;

const SettingsAuthorEdit = (props) => {
  const {
    author,
    setAuthor,
    authorImgId,
    setAuthorImgId,
    handleOpen
  } = props;
  /* console.log('author in edit:::', author); */
  const authorImageRef = useRef([]);
  const [authorFiles, setAuthorFiles] = useState([]);

  const authorImgHandler = async (event, index, authorId) => {
    let data = [...author];
    data[index]['author_img'] = event.target.files[0]
    setAuthor(data)

    let authorImg = [...authorFiles];
    authorImg[index] = authorImageRef.current[index].files[0];
    if (!authorImg[index].type.match(imageMimeType)) {
      alert("Image mime type is not valid");
      return;
    }


    let fileReader = new FileReader();
    fileReader.readAsDataURL(authorImg[index]);
    fileReader.onload = (e) => {
      const { result } = e.target;
      authorFiles.push(result)
      for (let i = 0; i < author.length; i++) {
        if (author[i]['id'] === authorId) {
          setAuthorImgId([...authorImgId, `author-img${index}${authorId}`])
          let imgId = `author-img${index}${authorId}`;
          document.getElementById(imgId).src = result;
        }
      }
    }
  }
  /* console.log('authorImgId :::', authorImgId); */
  const handleFormChange = (event, index) => {
    let data = [...author];
    data[index][event.target.name] = event.target.value;
    setAuthor(data);
  }

  return (
    author?.map((item, index) => {
      return (
        <div key={item.id || index + 1} className="w-full flex">
          <div className="flex flex-col gap-1 items-center justify-center w-1/3 px-8 py-4 border border-[#cecece] border-r-0">
            <div className="flex h-[52px] w-[52px] max-h-full rounded-[64px] overflow-hidden items-center mb-2">
              {
                item.author_img ?
                  <img className="w-full" id={"author-img" + index + item.id} src={`https://res.cloudinary.com/dfjtdwtka/image/upload/v${item?.author_img}`} alt={item?.author_name} />
                  :
                  <img className="w-full" id={"author-img" + index + item.id} src={avatar} alt="" />
              }
            </div>
            <div className="w-full flex justify-start">
              <input
                ref={item => (authorImageRef.current[index] = item)}
                type="file"
                id='image'
                accept='.png, .jpg, .jpeg'
                onChange={(event) => authorImgHandler(event, index, item.id)}
              />
            </div>
            <input
              name="author_name"
              className="w-full p-1 border border-neutral-300 text-sm text-neutral-800"
              type="text"
              placeholder="Author's Name"
              defaultValue={item.author_name || ""}
              onChange={(event) => handleFormChange(event, index)}
            />
            <input
              name="author_role"
              className="w-full p-1 border border-neutral-300 text-sm text-neutral-800"
              type="text"
              placeholder="Author's Role"
              defaultValue={item.author_role || ""}
              onChange={(event) => handleFormChange(event, index)}
            />
            <div className="w-full flex justify-center mt-2">
              <SmallOutlinedButton /* className="border border-neutral-400 px-2 py-1" */ onClick={() => handleOpen(index, item?.author_name, item?.author_img)}>Delete Author</SmallOutlinedButton>
            </div>
          </div>
          <div className="flex flex-col gap-2 w-2/3 px-8 py-4 border border-[#cecece]">
            <h2 className="font-bold text-neutral-800">Bio</h2>
            <div className="h-[120px] overflow-y-scroll border-b-[0.5px] border-t-[0.5px] border-neutral-300 text-sm text-neutral-800">
              <textarea
                className="h-full w-full p-2 border border-[#cecece]"
                name="author_bio"
                defaultValue={item.author_bio || ""}
                onChange={(event) => handleFormChange(event, index)}
              >
              </textarea>
            </div>
          </div>
        </div>
      )
    })
  );
};

export default SettingsAuthorEdit;

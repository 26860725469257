import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import HomeHero from './HomeHero'
import HomePostList from './HomePostList'

const Home = () => {
  const [posts, setPosts] = useState([]);
  const [author, setAuthor] = useState([]);
  const cat = useLocation().search

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/posts${cat}`);
        setPosts(res.data);
      } catch (err) {
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log('Error', err.message);
        }
      }
    };
    fetchData();
  }, [cat]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/authors`,
          { withCredentials: true });
        setAuthor(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {cat ?
        <header className='flex items-center mt-12 mb-6'>
          <div className='container flex flex-col md:flex-row items-start gap-1 mx-auto h-full'>
            <h1 className="text-[30px] sm:text-[40px] md:text-[52px] text-[#111111] font-semibold capitalize">{posts[0]?.cat}</h1>
          </div>
        </header>
        :
        <HomeHero posts={posts} />
      }
      <HomePostList posts={posts} cat={cat} />
    </>
  )
}

export default Home

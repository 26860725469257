import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Avatar from '@mui/material/Avatar';
import AuthorPostList from './AuthorPostList';

const Author = (props) => {
  const author = useLocation().state;
  const [authorStories, setAuthorStories] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/authors/${author.header_author}`,
          { withCredentials: true });
        setAuthorStories(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [author]);

  /* console.log('authorStories :::', authorStories);
  console.log('author :::', author); */

  return (
    <div className="min-h-[50vh] pt-2 sm:pt-6">
      <header className='flex items-center mt-6 sm:mt-12 mb-6'>
        <div className='container flex flex-col md:flex-row items-start gap-1 mx-auto h-full'>
          <div className={`${author?.author_name ? 'block' : 'hidden'} flex flex-col sm:flex-row justify-start gap-6 sm:gap-12`}>
            <div className="flex justify-start">
              <Avatar alt="avatar" src={`https://res.cloudinary.com/dfjtdwtka/image/upload/v${author?.author_img}`} sx={{ width: 88, height: 88 }} />
            </div>
            <div className="flex flex-col text-left">
              <h1 className="text-2xl sm:text-[30px] md:text-[52px] text-[#111111] font-semibold capitalize mb- sm:mb-2 md:mb-4">{author?.author_name}</h1>
              <h2 className="text-base sm:text-[20px] text-neutral-800 font-bold leading-10 mb-4">{author?.author_role}</h2>
              <p className="max-w-[810px] text-[#4d4d4d] text-sm sm:text-base text-left">{author?.author_bio}</p>
            </div>
          </div>
        </div>
      </header>
      <AuthorPostList posts={authorStories} />
    </div>
  )
}

export default Author

import React, { useState, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const BackTopBtn = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      return window.scrollY > 1000 ? setShow(true) : setShow(false);
    });
  });

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    show && (
      <button
        className={`sticky self-end mr-1 bottom-4 w-[48px] h-[48px] rounded-md bg-[#f64e65] hover:bg-accent text-white cursor-pointer flex justify-center items-center transition-all`}
        onClick={scrollToTop}
      >
        <ArrowUpwardIcon />
      </button>
    )
  );
};

export default BackTopBtn;

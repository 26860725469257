import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WriteHeader from "./WriteHeader";
import WriteProduct from "./WriteProduct";
import WriteParagraph from "./WriteParagraph";
import moment from "moment";
import axios from "axios";
const imageMimeType = /image\/(png|jpg|jpeg)/i;

const Write = () => {
  const navigate = useNavigate()

  //if it's post update from Post.jsx then const state brings in api response from mysql 
  const state = useLocation().state;
  let headerState;
  let productState;
  let productImg;
  let paragraphState;
  let paragraphImg;
  let productCloudinaryObj;
  let paragraphCloudinaryObj;
  if (state) {
    headerState = state[0][0];
    productState = state[1]
    paragraphState = state[2]
    for (let i = 0; i < productState.length; i++) {
      productImg = productState[i].product_img;
    }
    for (let i = 0; i < paragraphState.length; i++) {
      paragraphImg = paragraphState[i].paragraph_img;
    }
  }


  //header states
  const [cat, setCat] = useState(headerState?.cat || "");
  const [headerTitle, setHeaderTitle] = useState(headerState?.header_title || "");
  const [headerDescription, setHeaderDescription] = useState(headerState?.header_description || "");
  const [headerImage, setHeaderImage] = useState(headerState?.header_img || "");
  const [imageFiles, setImageFiles] = useState()
  const [headerImageDataURL, setHeaderImageDataURL] = useState(null);
  const [headerImgCredit, setHeaderImgCredit] = useState(headerState?.header_img_credit || "");
  const [headerText, setHeaderText] = useState(headerState?.header_text || "");
  const [headerSubtitle, setHeaderSubtitle] = useState(headerState?.header_subtitle || "");
  const [homeHero01, setHomeHero01] = useState(headerState?.home_hero_01 || 0);
  const [homeHero02, setHomeHero02] = useState(headerState?.home_hero_02 || 0);
  const [homeHero03, setHomeHero03] = useState(headerState?.home_hero_03 || 0);
  const [headerAuthor, setHeaderAuthor] = useState(headerState?.header_author || null);

  //product states
  const [productFields, setProductFields] = useState([
    {
      product_name: '',
      product_keyword: '',
      product_pros_01: '',
      product_pros_02: '',
      product_pros_03: '',
      product_cons_01: '',
      product_cons_02: '',
      product_cons_03: '',
      product_url: '',
      product_url_placeholder: '',
      product_text: '',
      product_img: '',
      product_img_credit: '',
      post_id: ''
    }]);

  //paragraph states
  const [paragraphFields, setParagraphFields] = useState([
    {
      paragraph_title: '',
      paragraph_text: '',
      paragraph_img: '',
      paragraph_img_credit: '',
      post_id: ''
    }]);

  //replace form fields if it is post update
  useEffect(() => {
    /* console.log("----------------------------- 1 load", productState); */
    if (productState) {
      const replaceFields = [...productState]
      setProductFields(replaceFields)
    }
    /* console.log("----------------------------- load", productState); */
  }, [productState]);

  useEffect(() => {
    /* console.log("----------------------------- 1 load", productState); */
    if (paragraphState) {
      const replaceFields = [...paragraphState]
      setParagraphFields(replaceFields)
      /* console.log("-----------------------------2 load", replaceFields); */
    }
    /* console.log("----------------------------- load", productState); */
  }, [paragraphState]);

  //header image upload
  const uploadHeaderImage = async () => {
    try {
      if (typeof headerImage === 'object') {
        const formData = new FormData();
        formData.append("file", headerImage);
        formData.append("upload_preset", 'e0qglk4j');
        const res = await axios.post(`https://api.cloudinary.com/v1_1/dfjtdwtka/image/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }, /* withCredentials: true */
        });
        console.log('res', res);
        return res.data;
      } else {
        return
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
    }
  };

  const headerImgHandler = (e) => {
    const headerImg = e.target.files[0];
    /* console.log("headerImg :: ", headerImg); */
    if (!headerImg.type.match(imageMimeType)) {
      alert("Image mime type is not valid");
      return;
    }
    setHeaderImage(headerImg);
    setImageFiles(headerImg)
  }

  //preview of image header
  useEffect(() => {
    let fileReader, isCancel = false;
    /* console.log("imageFiles :: ", imageFiles); */
    if (imageFiles) {
      fileReader = new FileReader();
      /* console.log("fileReader result2222:: ", fileReader); */
      fileReader.onload = (e) => {
        /* console.log("headerImage e :: ", e); */
        const { result } = e.target;
        if (result && !isCancel) {
          // console.log("headerImage result :: ", result);
          setHeaderImageDataURL(result)
        }
      }
      fileReader.readAsDataURL(imageFiles);
    }

    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    }
  }, [imageFiles]);

  //product image upload
  const uploadProductImage = async () => {
    try {
      /* console.log('productCloudinaryObj in upload :::', productCloudinaryObj); */
      if (productCloudinaryObj.length !== 0) {
        let productId = [];
        let promises = [];
        for (let i = 0; i < productCloudinaryObj.length; i++) {
          productId.push(productCloudinaryObj[i]['id']);
          /* console.log('productId :::', productId); */
          let file = productCloudinaryObj[i].product_img;
          /* console.log('file :::', file); */
          const formData = new FormData();
          formData.append("file", file);
          formData.append("upload_preset", 'e0qglk4j');
          promises.push(axios.post(`https://api.cloudinary.com/v1_1/dfjtdwtka/image/upload`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }).then((res) => {
            return res;
          }))
        }
        /* console.log('promises after id push :::', promises); */
        let data = await Promise.all(promises);

        for (let i = 0; i < data.length; i++) {
          data[i]['id'] = productId[i]
        }
        /* console.log('return data :::', data); */
        return data;
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
    }
  };

  //paragraph image upload
  const uploadParagraphImage = async () => {
    try {
      /* console.log('paragraphCloudinaryObj in upload :::', paragraphCloudinaryObj); */
      if (paragraphCloudinaryObj.length !== 0) {
        let paragraphId = [];
        let promises = [];
        for (let i = 0; i < paragraphCloudinaryObj.length; i++) {
          paragraphId.push(paragraphCloudinaryObj[i]['id']);
          /* console.log('paragraphId :::', paragraphId); */
          let file = paragraphCloudinaryObj[i].paragraph_img;
          /* console.log('file :::', file); */
          const formData = new FormData();
          formData.append("file", file);
          formData.append("upload_preset", 'e0qglk4j');
          promises.push(axios.post(`https://api.cloudinary.com/v1_1/dfjtdwtka/image/upload`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }).then((res) => {
            return res;
          }))
        }
        /* console.log('promises after id push :::', promises); */
        let data = await Promise.all(promises);

        for (let i = 0; i < data.length; i++) {
          data[i]['id'] = paragraphId[i]
        }
        /* console.log('return data :::', data); */
        return data;
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
    }
  };

  //submit form
  const handleSubmit = async (e) => {
    e.preventDefault();

    //submit header
    let headerImgUrl = await uploadHeaderImage();
    let cloudinaryUrl = headerImage;
    if (headerImgUrl) {
      cloudinaryUrl = headerImgUrl.version + '/' + headerImgUrl.public_id
    }

    //submit product
    let updatedProductImgUrl;
    productCloudinaryObj = productFields.filter(object => {
      return typeof object.product_img === 'object'
    })
    let productImgUrl = await uploadProductImage();
    /* console.log('productImgUrl :::', productImgUrl); */
    if (productImgUrl !== undefined) {
      let productId = [];
      for (let i = 0; i < productImgUrl.length; i++) {
        productId.push(productImgUrl[i]['id']);
      }
      updatedProductImgUrl = productFields.filter((object, index) => {
        return typeof object.product_img === 'object'
      })
      /* console.log('updatedProductImgUrl :::', updatedProductImgUrl); */
      for (let i = 0; i < updatedProductImgUrl.length; i++) {
        updatedProductImgUrl[i]['product_img'] = productImgUrl[i].data.version + '/' + productImgUrl[i].data.public_id
      }
    }

    //submit paragraph
    let updatedParagraphImgUrl;
    paragraphCloudinaryObj = paragraphFields.filter(object => {
      return typeof object.paragraph_img === 'object'
    })
    let paragraphImgUrl = await uploadParagraphImage();
    /* console.log('paragraphImgUrl :::', paragraphImgUrl); */
    if (paragraphImgUrl !== undefined) {
      let paragraphId = [];
      for (let i = 0; i < paragraphImgUrl.length; i++) {
        paragraphId.push(paragraphImgUrl[i]['id']);
      }
      updatedParagraphImgUrl = paragraphFields.filter((object, index) => {
        return typeof object.paragraph_img === 'object'
      })
      /* console.log('updatedParagraphImgUrl :::', updatedParagraphImgUrl); */
      for (let i = 0; i < updatedParagraphImgUrl.length; i++) {
        updatedParagraphImgUrl[i]['paragraph_img'] = paragraphImgUrl[i].data.version + '/' + paragraphImgUrl[i].data.public_id
      }
    }

    try {
      headerState
        ? await axios(`${process.env.REACT_APP_API_URL}/posts/${headerState.id}`, {
          data: {
            cat,
            header_title: headerTitle,
            header_description: headerDescription,
            header_img: cloudinaryUrl,
            header_img_credit: headerImgCredit,
            header_text: headerText,
            header_subtitle: headerSubtitle,
            home_hero_01: homeHero01,
            home_hero_02: homeHero02,
            home_hero_03: homeHero03,
            header_author: headerAuthor,
            productFields,
            paragraphFields
          },
          method: 'PUT',
          withCredentials: true
        }).then(navigate("/"))
        : await axios(`${process.env.REACT_APP_API_URL}/posts/`, {
          data: {
            cat,
            header_title: headerTitle,
            header_date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
            header_description: headerDescription,
            header_img: headerImage ? cloudinaryUrl : "",
            header_img_credit: headerImgCredit,
            header_text: headerText,
            header_subtitle: headerSubtitle,
            home_hero_01: homeHero01,
            home_hero_02: homeHero02,
            home_hero_03: homeHero03,
            header_author: headerAuthor,
            productFields,
            paragraphFields
          },
          method: 'POST',
          withCredentials: true
        }).then(navigate("/"));
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
    }
  };
  /* console.log('conclusionFields :::', conclusionFields); */
  return (
    <div className="w-full mt-8 mb-24 flex flex-col gap-12 text-neutral-800">
      <WriteHeader
        handleSubmit={handleSubmit}
        headerImgHandler={headerImgHandler}
        headerState={headerState}
        cat={cat}
        setCat={setCat}
        headerTitle={headerTitle}
        setHeaderTitle={setHeaderTitle}
        headerDescription={headerDescription}
        setHeaderDescription={setHeaderDescription}
        headerImage={headerImage}
        setHeaderImage={setHeaderImage}
        headerImgCredit={headerImgCredit}
        setHeaderImgCredit={setHeaderImgCredit}
        headerText={headerText}
        setHeaderText={setHeaderText}
        headerSubtitle={headerSubtitle}
        setHeaderSubtitle={setHeaderSubtitle}
        homeHero01={homeHero01}
        setHomeHero01={setHomeHero01}
        homeHero02={homeHero02}
        setHomeHero02={setHomeHero02}
        homeHero03={homeHero03}
        setHomeHero03={setHomeHero03}
        headerImageDataURL={headerImageDataURL}
        headerAuthor={headerAuthor}
        setHeaderAuthor={setHeaderAuthor}
      />
      <WriteProduct
        productFields={productFields}
        setProductFields={setProductFields}
      />
      <WriteParagraph
        paragraphFields={paragraphFields}
        setParagraphFields={setParagraphFields}
      />
    </div>
  );
};

export default Write;

import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const ColorButton = styled(Button)({
    textTransform: 'none',
    backgroundColor: '#FF2E4B',
    '&:hover': {
        backgroundColor: '#FF6177',
    },
})

const ContainedButton = ({ onClick, children }) => {

    return (
        <ColorButton className='flex gap-2' onClick={onClick} variant="contained" disableElevation>{children}</ColorButton>
    );
}

export default ContainedButton;
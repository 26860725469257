import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/authContext";
import { Link } from "react-router-dom";
import moment from "moment";
import OutlinedButton from '../../components/ui/buttons/OutlinedButton';
import noImgAvailable from "../../img/no_img_available.jpg"
import Pagination from '@mui/material/Pagination';
/* import ColorButton from '../../components/ui/ColorButton'; */

const HomePostCard = (props) => {
    const { posts, cat } = props;
    const { next, setNext, postsPerColumn } = useContext(AuthContext);

    const handleMorePosts = () => {
        setNext(next + postsPerColumn);
    };

    const getText = (html) => {
        const doc = new DOMParser().parseFromString(html, "text/html")
        return doc.body.textContent
    }

    /* let sortedCards = posts.sort((a, b) => new Date(...a.header_date)); */
    const [pageNumber, setPageNumber] = useState(1);
    const postsPerPage = 10;

    const pagesVisited = (pageNumber - 1) * postsPerPage;
    const pageCount = Math.ceil(posts.length / postsPerPage)
    /* console.log('next :::', next); */
    const changePage = (e, p) => {
        setPageNumber(p)
    }

    const displayPosts = posts?.filter(item => cat ? item
        : item
    ).sort((a, b) => b.id > a.id ? 1 : -1).slice(pagesVisited, pagesVisited + postsPerPage).map(item => {
        return (
            <div key={item.id} className='h-fit w-full md:w-[95%] py-4 border-b border-[#dedede] group'>
                <Link className="link" state={posts} to={`/post/${item.id}`}>
                    <div className='flex md:flex-row-reverse h-fit w-full'>
                        <div className='h-fit w-4/5 md:ml-4'>
                            <h4 className='text-accent font-bold capitalize'>{item?.cat}</h4>
                            <h2 className='text-neutral-800 group-hover:text-sky-600 font-bold text-base sm:text-2xl mb-2 sm:mb-0'>{item?.header_title}</h2>
                            <p className='hidden md:block text-[#757575] font-medium mb-6'>{getText(item?.header_description)}</p>
                            <div className="text-[#959595] font-semibold text-xs flex gap-3">
                                <p>By {item?.author_name}</p>
                                <p className="hidden sm:block">{moment(item?.header_date).fromNow()}</p>
                            </div>
                        </div>
                        <div className='h-16 sm:h-24 md:h-28 w-fit border border-neutral-300 flex items-center'>
                            {
                                !item.header_img ?
                                    <img className="h-full w-auto" src={noImgAvailable} alt="" />
                                    :
                                    <img className="h-full w-auto" src={`https://res.cloudinary.com/dfjtdwtka/image/upload/v${item?.header_img}`} alt="" />
                            }
                        </div>
                    </div>
                </Link>
            </div>
        )
    })

    return (
        <>
            {next < 8 || cat ?
                posts.filter(item => cat ? item
                    : item.home_hero_01 === 0 && item.home_hero_02 === 0 && item.home_hero_03 === 0 ? item : null
                ).sort((a, b) => b.id > a.id ? 1 : -1).slice(0, next)?.map((item) => {
                    return (
                        <div key={item.id} className='h-fit w-full md:w-[95%] py-4 border-b border-[#dedede] group'>
                            <Link className="link" state={posts} to={`/post/${item.id}`}>
                                <div className='flex md:flex-row-reverse h-fit w-full'>
                                    <div className='h-fit w-4/5 md:ml-4'>
                                        <h4 className='text-accent font-bold capitalize'>{item?.cat}</h4>
                                        <h2 className='text-neutral-800 group-hover:text-sky-600 font-bold text-base sm:text-2xl mb-2 sm:mb-0'>{item?.header_title}</h2>
                                        <p className='hidden md:block text-[#757575] font-medium mb-6'>{getText(item?.header_description)}</p>
                                        <div className="text-[#959595] font-semibold text-xs flex gap-3">
                                            <p>By {item?.author_name}</p>
                                            <p className="hidden sm:block">{moment(item?.header_date).fromNow()}</p>
                                        </div>
                                    </div>
                                    <div className='h-16 sm:h-24 md:h-28 border border-neutral-300 flex items-center'>
                                        {
                                            item.header_img ?
                                                <img className="h-full w-auto" src={`https://res.cloudinary.com/dfjtdwtka/image/upload/v${item?.header_img}`} alt="" />
                                                :
                                                <img className="h-full w-auto" src={noImgAvailable} alt="" />
                                        }
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )
                })
                :
                <>
                    {displayPosts}
                    <div className="mt-8 flex justify-center">
                        <Pagination count={pageCount} onChange={changePage} />
                    </div>
                </>
            }
            {next < 8 && (
                <div className="flex justify-center my-16">
                    <OutlinedButton
                        onClick={handleMorePosts}
                    >
                        MORE STORIES
                    </OutlinedButton>
                </div>
            )}
        </>
    );
};

export default HomePostCard;

import React from "react";
import { useState, useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ClearIcon from '@mui/icons-material/Clear';

const Register = () => {
  const [inputs, setInputs] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [err, setError] = useState(null);

  const navigate = useNavigate();

  const { toggleRegister } = useContext(AuthContext);

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`,
        inputs);
      navigate("/");
      toggleRegister();
    } catch (err) {
      setError(err.response.data);
    }
  };

  return (
    <div className="container mx-auto flex items-center">
      <form className="w-full flex items-center justify-between">
        <div className="flex gap-6">
          <input
            required
            type="text"
            placeholder="username"
            name="username"
            onChange={handleChange}
          />
          <input
            required
            type="email"
            placeholder="email"
            name="email"
            onChange={handleChange}
          />
          <input
            required
            type="password"
            placeholder="password"
            name="password"
            onChange={handleChange}
          />
          <button onClick={handleSubmit} className="text-neutral-800 hover:text-black border border-neutral-400 rounded px-2">Register</button>
        </div>
        {err && <p>{err}</p>}
        <Link onClick={toggleRegister}><ClearIcon /> Cancel</Link>
      </form>
    </div>
  );
};

export default Register;
